import React, { useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import "react-loading-skeleton/dist/skeleton.css";
import LatestMoviesComponent from "../components/moviesComponent/LatestMoviesComponent";
import LatestShowsComponent from "../components/moviesComponent/LatestShowsComponent";
import SliderMovie from "../components/moviesComponent/SliderMovie";

import { Footer } from "../components/shared/Footer";
import { Navbar } from "../components/shared/Navbar";
import MovieService from "../services/MovieService";

//slide
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { PopularSlid } from "../components/moviesComponent/PopularSlid";
import AdsService from "../services/AdsService";
import { Link, useNavigate } from "react-router-dom";
import LocalAdsComponent from "../components/ads/LocalAdsComponent";

import YenePayCheckoutButton from "react-yenepay-express-checkout";
import Loading from "../components/shared/Loading";
// import 'react-yenepay-express-checkout/dist/index.css'

const options = {
  items: 3,
  lazyLoad: true,
  // responsiveClass: true,
  responsive: {
    0: {
      items: 1,
      nav: false,
      height: "500px",
    },
    720: {
      items: 1,
      nav: false,
      width: "100%",
    },
    1020: {
      items: 1,
      nav: false,
      width: "100%",
    },
  },
};

const popular = {
  items: 3,
  lazyLoad: true,
  responsiveClass: true,
  responsive: {
    0: {
      items: 2,
      nav: false,
    },
    720: {
      items: 3,
      nav: false,
    },
    1020: {
      items: 5,
      nav: false,
    },
  },
};

export const Home = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // useEffect(() => {
  //   setTimeout(() => {
  //     localStorage.clear();
  //     navigate("/signin");
  //     window.location.reload();
  //   }, 10000);
  // }, []);

  console.log(localStorage.getItem("token"));

  const {
    data: slideMoviesData,
    isLoading: slideMoviesLoading,
    isFetching: slideMoviesFetching,
    isError: slideMoviesError,
  } = useQuery("slideMovies", MovieService.slideMovies);

  const {
    data: latestMoviesData,
    isLoading: latestMoviesLoading,
    isFetching: latestMoviesFetching,
    isError: latestMoviesError,
  } = useQuery("latestMovies", MovieService.latestMovies);

  const {
    data: latestShowsData,
    isLoading: latestShowsLoading,
    isFetching: latestShowsFetching,
    isError: latestShowsError,
  } = useQuery("latestShows", MovieService.latestShows);

  const {
    data: recommendedMoviesData,
    isLoading: recommendedMoviesLoading,
    isFetching: recommendedMoviesFetching,
    isError: recommendedMoviesError,
  } = useQuery("recommendedMovies", MovieService.recommendedMovies);

  const {
    data: localAdsData,
    isLoading: localAdsLoading,
    isError: localAdsError,
  } = useQuery("localAds", AdsService.localAds, {
    onError: () => {
      // localStorage.clear();
      // navigate('/signin')
      // window.location.reload();
    },
  });

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } =
    useMutation("addToWhishList", MovieService.addToWishList, {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail");
      },
    });

  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE;

  const onWhishListFormSubmit = (defaultValues) => {
    wishListMutate(defaultValues);
  };

  if (slideMoviesLoading) return <Loading />;

  if (latestMoviesLoading) return <Loading />;

  if (latestShowsLoading) return <Loading />;

  if (
    recommendedMoviesError ||
    latestShowsError ||
    slideMoviesError ||
    localAdsError
  )
    return (
      <div className="text-center">
        Something Went Wrong, Please Check Your Network
      </div>
    );

  return (
    <div style={{ backgroundColor: "black" }}>
      <div className="main-slider" id="main-slider">
        <div className="slider big-slider slider-wrap d-none d-lg-block d-md-none">
          <OwlCarousel {...options} className="owl-theme" loop margin={10} nav>
            {!slideMoviesLoading &&
              slideMoviesData.data.map((data) => (
                <div key={data._id}>
                  <SliderMovie
                    id={data._id}
                    title={data.title}
                    description={data.description}
                    image={data.thumbnail}
                    createdAt={data.createdAt}
                    onWhishListFormSubmit={onWhishListFormSubmit}
                  />
                </div>
              ))}
          </OwlCarousel>
        </div>
        <div className="slider big-slider slider-wrap d-block d-lg-none">
          <OwlCarousel {...options} className="owl-theme" loop margin={10} nav>
            {!slideMoviesLoading &&
              slideMoviesData.data.map((data) => (
                <div key={data._id}>
                  <SliderMovie
                    id={data._id}
                    title={data.title}
                    description={data.description}
                    image={data.poster}
                    createdAt={data.createdAt}
                    onWhishListFormSubmit={onWhishListFormSubmit}
                  />
                </div>
              ))}
          </OwlCarousel>
        </div>
      </div>
      <section className="pupular">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="block-title">Local Ads</h2>
              <OwlCarousel
                {...popular}
                className="owl-theme"
                loop
                margin={10}
                nav
              >
                {localAdsData &&
                  localAdsData.data.map((data) => (
                    <div key={data._id}>
                      <a href={`${data.link}`} target="_blank">
                        <LocalAdsComponent
                          id={data._id}
                          title={data.title}
                          description={data.description}
                          image={data.photo}
                          postedDate={moment(data.postedDate).format("YYYY")}
                          onWhishListFormSubmit={onWhishListFormSubmit}
                        />
                      </a>
                    </div>
                  ))}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <div className="main-content">
        <section className="main-tabs" style={{ marginTop: "-120px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="season-tabs">
                  <ul
                    className="nav nav-pills mb-3"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        data-toggle="pill"
                        href="#pills-additions"
                        role="tab"
                        aria-selected="true"
                      >
                        Recommended
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="#pills-movies"
                        role="tab"
                        aria-selected="false"
                      >
                        Movies
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="#pills-shows"
                        role="tab"
                        aria-selected="false"
                      >
                        Series
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="tab-content" id="pills-tabContent">
                  <div
                    id="pills-additions"
                    className="tab-pane animated fadeInRight show active"
                  >
                    <div className="row">
                      {recommendedMoviesData &&
                      recommendedMoviesData.data.length > 0
                        ? recommendedMoviesData.data.map(
                            (recommendedMovies) => {
                              return (
                                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                  <LatestMoviesComponent
                                    key={recommendedMovies.id}
                                    id={recommendedMovies._id}
                                    title={recommendedMovies.title}
                                    image={`${imageURL}/${recommendedMovies.poster}`}
                                    category={recommendedMovies.category}
                                    postedDate={recommendedMovies.createdAt}
                                    views={recommendedMovies.views}
                                    isLoading={recommendedMoviesLoading}
                                    onWhishListFormSubmit={
                                      onWhishListFormSubmit
                                    }
                                  />
                                </div>
                              );
                            }
                          )
                        : "No data found"}
                    </div>
                  </div>

                  <div
                    id="pills-movies"
                    className="tab-pane animated fadeInRight"
                  >
                    <div className="row">
                      {latestMoviesData && latestMoviesData.data.length > 0
                        ? latestMoviesData.data.map((latestMovies) => {
                            return (
                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                <LatestMoviesComponent
                                  key={latestMovies.id}
                                  id={latestMovies._id}
                                  title={latestMovies.title}
                                  image={`${imageURL}/${latestMovies.poster}`}
                                  category={latestMovies.category}
                                  postedDate={latestMovies.createdAt}
                                  views={latestMovies.views}
                                  isLoading={latestMoviesLoading}
                                  onWhishListFormSubmit={onWhishListFormSubmit}
                                />
                              </div>
                            );
                          })
                        : "No data found"}
                    </div>
                  </div>

                  <div
                    id="pills-shows"
                    className="tab-pane animated fadeInRight"
                  >
                    <div className="row">
                      {latestShowsData && latestShowsData.data.length > 0
                        ? latestShowsData.data.map((latestShows) => {
                            return (
                              <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                <LatestShowsComponent
                                  key={latestShows.id}
                                  id={latestShows._id}
                                  title={latestShows.title}
                                  image={`${imageURL}/${latestShows.poster}`}
                                  category={latestShows.category}
                                  postedDate={latestShows.createdAt}
                                  views={latestShows.views}
                                  isLoading={latestShowsLoading}
                                  onWhishListFormSubmit={onWhishListFormSubmit}
                                />
                              </div>
                            );
                          })
                        : "No data found"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pupular" style={{ marginTop: "-80px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="block-title">Popular Movies</h2>
                <OwlCarousel
                  {...popular}
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                >
                  {!slideMoviesLoading &&
                    slideMoviesData.data.map((data) => (
                      <div key={data._id}>
                        <Link to={`/${data.link}`}>
                          <PopularSlid
                            id={data._id}
                            title={data.title}
                            description={data.description}
                            image={data.poster}
                            postedDate={moment(data.postedDate).format("YYYY")}
                            onWhishListFormSubmit={onWhishListFormSubmit}
                          />
                        </Link>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <section className="trending" style={{ marginTop: "-80px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="block-title">Latest Movies</h2>
                <div className="row">
                  {latestMoviesData && latestMoviesData.data.length > 0
                    ? latestMoviesData.data.map((latestMovies) => {
                        return (
                          <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                            <LatestMoviesComponent
                              key={latestMovies.id}
                              id={latestMovies._id}
                              title={latestMovies.title}
                              image={`${imageURL}/${latestMovies.poster}`}
                              category={latestMovies.category}
                              postedDate={latestMovies.createdAt}
                              views={latestMovies.views}
                              isLoading={latestMoviesLoading}
                              onWhishListFormSubmit={onWhishListFormSubmit}
                            />
                          </div>
                        );
                      })
                    : "No data found"}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pupular" style={{ marginTop: "-80px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="block-title">Local Ads</h2>
                <OwlCarousel
                  {...popular}
                  className="owl-theme"
                  loop
                  margin={10}
                  nav
                >
                  {localAdsData &&
                    localAdsData.data.map((data) => (
                      <div key={data._id}>
                        <a href={`${data.link}`} target="_blank">
                          <LocalAdsComponent
                            id={data._id}
                            title={data.title}
                            description={data.description}
                            image={data.photo}
                            postedDate={moment(data.postedDate).format("YYYY")}
                            onWhishListFormSubmit={onWhishListFormSubmit}
                          />
                        </a>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>

        <section className="suggested pt-0" style={{ marginTop: "-30px" }}>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="block-title">Latest Tv Series</h2>
              </div>
            </div>

            <div className="row">
              {latestShowsData && latestShowsData.data.length > 0
                ? latestShowsData.data.map((latestMovies) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                        <LatestShowsComponent
                          key={latestMovies.id}
                          id={latestMovies._id}
                          title={latestMovies.title}
                          image={`${imageURL}/${latestMovies.poster}`}
                          category={latestMovies.category}
                          postedDate={latestMovies.createdAt}
                          views={latestMovies.views}
                          isLoading={latestMoviesLoading}
                          onWhishListFormSubmit={onWhishListFormSubmit}
                        />
                      </div>
                    );
                  })
                : "No data found"}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
