import React from "react";
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import AuthService from "../../services/AuthService";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from "react-router-dom";
import UpdateProfile from "../authComponent/UpdateProfile";
import { toast } from 'react-toastify';
import Loading from "./Loading";


export const Account = () => {
  const queryClient = useQueryClient();
  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  console.log(userData.user)


  const { data: customerProfile, isLoading: customerProfileLoading, isFetching: customerProfileFetching, isError: customerProfileError } = useQuery(
    'customerProfile',
    AuthService.getProfile
  )

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),
    newPassword: Yup.string().required('New Password is required'),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });



  // const defaultValues = userData.user
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    // defaultValues,
    resolver: yupResolver(validationSchema)
  });


  const { mutateAsync: updatePasswordMutate, isLoading: updatePasswordLoading } = useMutation(
    "updatePassword",
    AuthService.updatePassword,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customerProfile")
      }
    }
  )

  //Remove User
  const { mutateAsync: removeUserMutate, isLoading: removeUserLoading } = useMutation(
    "removeUser",
    AuthService.removeUser,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("customerProfile")
      },
      onError: (error) => {
        toast.error(error.error)
      }
    }
  )

  React.useEffect(() => {
    if (customerProfileLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center">
          <h3>Loading....</h3>
        </div>
      )
  
    } 
  }, []);



  if (customerProfileLoading)
    return (
      <Loading />
    )

  if (customerProfileFetching)
    return (
        <Loading />
    )
    if (customerProfileError)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <h3>Something Went Wrong</h3>
      </div>
    )

  const newCustomerProfile = customerProfile.data
  console.log(newCustomerProfile)



  const onPasswordFormSubmit = (data) => {
    console.log(data)
    updatePasswordMutate(data)
  }


  return (
    <div style={{ backgroundColor: 'black' }}>
      <div className="main-content">
        <section className="account-settings">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="content-settings">
                  <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link active"
                        data-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-selected="true"
                      >
                        Profile Settings
                      </a>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <a
                        className="nav-link"
                        data-toggle="pill"
                        href="#pills-account"
                        role="tab"
                        aria-selected="false"
                      >
                        Account Settings
                      </a>
                    </li> */}
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      id="pills-profile"
                      className="tab-pane animated fadeInRight show active"
                    >
                      <UpdateProfile defaultValues={newCustomerProfile}  />
                      <h5 className="mb-3 mt-3 pb-3 section-border">
                        Change Password
                      </h5>
                      <form onSubmit={handleSubmit(onPasswordFormSubmit)}>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>Old Password</label>
                              <input {...register('oldPassword')} className="form-control" placeholder="" type="password" />
                              <span className='text-danger'>{errors.oldPassword?.message}</span>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>New Password</label>
                              <input {...register('newPassword')} className="form-control" placeholder="" type="password" />
                              <span className='text-danger'>{errors.newPassword?.message}</span>
                              {/* <span className='text-danger'>{errors.newPassword?.message}</span> */}
                            </div>
                          </div>
                        </div>
                        <div className="form-group d-flex align-items-center mt-3">
                          <button
                            type="submit"
                            className="hvr-sweep-to-right btn btn-primary"
                          >
                            {updatePasswordLoading ? "Loading..." : 'Save'}
                          </button>
                        </div>
                      </form>
                    </div>
                    <div
                      id="pills-account"
                      className="tab-pane animated fadeInRight"
                    >

                      <div className="row mb-2">
                        <div className="col">
                          <h5 className="mb-3 mt-3 pb-3">
                            Profiles

                          </h5>
                        </div>
                        {/* <div className="col">
                          <Link to="/add-new-user" className="btn hvr-sweep-to-right">
                            Add New User
                          </Link>
                        </div> */}
                      </div>
                      <hr />
                      <form id="account-settings-form" action="#" method="post">

                        <div className="row">
                          <div className="col-md-12">
                            <div className="row">
                              {/* {
                                newCustomerProfile.profiles.map(users => {
                                  return (
                                    <div key={users.id} className="col-lg-4 col-md-4 col-6 mb-5">
                                      <div>
                                        <div className="form-group">
                                          <div className="settings-avatar">
                                            <img
                                              src="assets/images/avatar/user.jpg"
                                              className="img-fluid d-block m-2"
                                              alt="User avatar"
                                              style={{ width: '100px' }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-10">
                                        <div className="form-group">
                                          <p style={{ fontSize: '20px' }}>{users.title}</p>
                                        </div>
                                        <div className="form-group">
                                          <p className="text-danger" style={{ fontSize: '18px' }}
                                            onClick={() => {
                                              removeUserMutate(users._id)
                                            }}
                                          >
                                            {removeUserLoading ? "Loading" : 'Remove'}
                                          </p>
                                        </div>
                                   
                                      </div>

                                    </div>
                                  )
                                })
                              } */}

                            </div>
                          </div>
                        </div>
                        {/* <div className="form-group d-flex align-items-center mt-3">
                          <Link to="/add-new-user" className="btn hvr-sweep-to-right">
                            Add
                          </Link>

                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
