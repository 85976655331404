import React from "react";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient, useQuery } from "react-query";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Video from "../hooks/Video";
import "../css/starRating.css";
import MovieService from "../services/MovieService";
import Skeleton from "react-loading-skeleton";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  // TwitterShareButton,
} from "react-share";
import Instagram from "react-share-icons/lib/Instagram";
import "react-loading-skeleton/dist/skeleton.css";
import Loading from "../components/shared/Loading";

const MovieDetail = () => {
  const queryClient = useQueryClient();
  const imagePath = process.env.REACT_APP_API_SERVER_IMAGE;
  const videoPath = process.env.REACT_APP_API_SERVER_VIDEO;
  const [ratingValue, setRating] = React.useState("5");

  const playerRef = React.useRef(null);
  const movieId = useParams();

  const [moreComment, setMoreComment] = React.useState(false);

  const validationSchema = Yup.object().shape({
    _id: Yup.string(),
    rating: Yup.string(),
    comment: Yup.string(),
    movieId: Yup.string(),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    //   defaultValues,
    resolver: yupResolver(validationSchema),
  });

  // const _id = movieId.movieId

  const {
    data: movieData,
    isLoading: movieLoading,
    isError: movieErorr,
  } = useQuery(["movieDetail", movieId.movieId], () =>
    MovieService.MovieDetail(movieId.movieId)
  );

  const { mutateAsync: commentMutate, isLoading: commentMutateLoading } =
    useMutation(
      ["postComment", movieId.movieId],
      MovieService.makeMovieComment,
      {
        onSuccess: () => {
          queryClient.invalidateQueries("movieDetail");
        },
        // onMutate: async newTodo => {
        //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        //   await queryClient.cancelQueries(['movieDetail'])

        //   // Snapshot the previous value
        //   const previousTodos = queryClient.getQueryData(['movieDetail'])

        //   // Optimistically update to the new value
        //   queryClient.setQueryData(['movieDetail'], old => [...old, newTodo])

        //   // Return a context object with the snapshotted value
        //   return { previousTodos }
        // },
        // // If the mutation fails, use the context returned from onMutate to roll back
        // onError: (err, newTodo, context) => {
        //   queryClient.setQueryData(['movieDetail'], context.previousTodos)
        // },
        // // Always refetch after error or success:
        // onSettled: () => {
        //   queryClient.invalidateQueries(['movieDetail'])
        // },
      }
    );

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } =
    useMutation(
      ["addToWhishList", movieId.movieId],
      MovieService.addToWishList,
      {
        onSuccess: () => {
          queryClient.invalidateQueries("movieDetail");
        },
      }
    );

  if (movieLoading) return <Loading />;

  const getMovieData = movieLoading ? "Loading..." : movieData.data.movies;
  const newMovieData = movieData.data.movies.fileName;
  var playMovie = newMovieData;
  const commentData = movieData.data.comments;

  const moreCommentChange = () => {
    moreComment ? setMoreComment(false) : setMoreComment(true);
  };

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: `${videoPath}/${playMovie}.m3u8`,
        type: "application/x-mpegURL",
      },
    ],
    poster: `${imagePath}/${getMovieData.thumbnail}`,
    fill: true,
  };

  const shareMovie = "https://etnet.et/movie";

  //Make Comments
  const onFormSubmit = (commentData) => {
    commentMutate(commentData);
    reset();
  };

  //Add To WhishList
  const onWhishListFormSubmit = (defaultValues) => {
    wishListMutate(defaultValues);
  };

  return (
    <div
      style={{
        backgroundColor: "black",
      }}
    >
      <div className="video-container ">
        <Video
          options={videoJsOptions}
          // onReady={handlePlayerReady}
          style={{
            margin: "0px",
            padding: "0px",
            height: "200px",
          }}
        />
      </div>
      <div className="main-content ">
        <section className="play-details">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="play-thumb mb-4">
                      <img
                        className="img-fluid"
                        src={`${imagePath}/${getMovieData.poster}`}
                        alt=""
                      />
                      <div className="top-badge">
                        <div className="video-badge">
                          <img
                            className="img-fluid"
                            src="../assets/images/top-movies.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="thumb-details text-center">
                      <span>1080p</span>
                      <span>24p</span>
                      <span>
                        <img
                          className="img-fluid"
                          src="../assets/images/dts-logo.png"
                          alt=""
                        />
                      </span>
                      <span>7.1</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="play-details-content">
                  <div className="title-block d-flex align-items-center justify-content-between">
                    <h2 className="play-title">{getMovieData.title}</h2>
                  </div>
                  <div className="row col-12">
                    <div className="col-lg-6 col-md-6">
                      {getMovieData.rating == 0 ? (
                        <h2 className="on">""</h2>
                      ) : (
                        ""
                      )}
                      {getMovieData.rating == 1 ? (
                        <h2 className="on">
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                        </h2>
                      ) : (
                        ""
                      )}
                      {getMovieData.rating > 1 && getMovieData.rating < 2 ? (
                        <h2 className="on">
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                        </h2>
                      ) : (
                        ""
                      )}
                      {getMovieData.rating > 2 && getMovieData.rating < 3 ? (
                        <h2 className="on">
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                        </h2>
                      ) : (
                        ""
                      )}
                      {getMovieData.rating > 3 && getMovieData.rating < 4 ? (
                        <h2 className="on">
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                        </h2>
                      ) : (
                        ""
                      )}
                      {getMovieData.rating > 4 && getMovieData.rating < 5 ? (
                        <h2 className="on">
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                          <span
                            className="star"
                            style={{
                              fontSize: "36px",
                            }}
                          >
                            &#9733;
                          </span>
                        </h2>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* <div className="details-info mb-4">
                    <span>
                      <i className="icofont-user mr-2" aria-hidden="true"></i>{getMovieData.views}
                    </span>
                    <span>
                      <i className="icofont-clock-time mr-2" aria-hidden="true"></i>{" "}
                      2hr 45min
                    </span>
                    <span>
                      <i
                        className="icofont-simple-smile mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      2021
                    </span>
                    <span>
                      <i className="icofont-movie mr-2" aria-hidden="true"></i>{" "}
                      Action
                    </span>
                    <span>
                      <i className="icofont-world mr-2" aria-hidden="true"></i>{" "}
                      United States
                    </span>
                  </div> */}
                  <div className="details-desc">
                    <p>{getMovieData.description}</p>
                  </div>
                  <div className="movie-persons mb-4">
                    <div className="person-block">
                      <h5 className="title">Director</h5>
                      <p className="name">{getMovieData.director}</p>
                    </div>
                    <div className="person-block">
                      <h5 className="title">Cast</h5>
                      <p>{getMovieData.cast}</p>
                    </div>
                  </div>
                  <div className="details-buttons">
                    <div className="row d-flex align-items-center">
                      {/* <div className="col-6 col-xl mb-xl-0 mb-3">
                        <a
                          href="/play"
                          className="btn d-block hvr-sweep-to-right"
                          tabIndex="0"
                        >
                          <i
                            className="icofont-ui-play mr-2"
                            aria-hidden="true"
                          ></i>
                          Play
                        </a>
                      </div> */}
                      <div className="col-6 col-xl mb-xl-0 mb-3">
                        <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                          <input
                            type="hidden"
                            {...setValue("movieId", movieId.movieId, {
                              shouldTouch: true,
                            })}
                          />{" "}
                          {/* <a
                         type="submit"
                          className="btn d-block hvr-sweep-to-right"
                          tabIndex="0"
                        >
                          <i className="icofont-plus mr-2" aria-hidden="true"></i>MY
                          List
                        </a> */}
                          <button
                            type="submit"
                            className="hvr-sweep-to-right btn btn-primary"
                          >
                            <i
                              className="icofont-plus mr-2"
                              aria-hidden="true"
                            ></i>
                            {wishListMutateLoading ? "Loading" : "My List"}
                          </button>
                        </form>
                      </div>
                      <div className="col-6 col-xl mb-xl-0">
                        <a
                          id="share"
                          className="btn hvr-sweep-to-right d-block"
                          tabIndex="0"
                          data-toggle="modal"
                          data-target="#share-modal"
                        >
                          <i
                            className="icofont-share mr-2"
                            aria-hidden="true"
                          ></i>
                          Share
                        </a>

                        <div
                          className="modal fade"
                          id="share-modal"
                          tabIndex="0"
                          role="dialog"
                          aria-labelledby="share-modal"
                          aria-hidden="true"
                        >
                          <div
                            className="modal-dialog modal-lg"
                            role="document"
                            id="sharemodal"
                          >
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5 className="modal-title">Share</h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">
                                    <i className="fas fa-times"></i>
                                  </span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="icon-container">
                                  {/* <div className="icon-block">
                                    <i className="social-icon fab fa-twitter fa-2x"></i>
                                    <p>Twitter</p>
                                  </div> */}
                                  <div className="row">
                                    <div className="gol-lg4 col-md-6 col-sm-12">
                                      <FacebookShareButton
                                        url={`${shareMovie}/${getMovieData._id}`}
                                        quote={`${getMovieData.title}`}
                                      >
                                        <div className="icon-block">
                                          <i className="social-icon fab fa-facebook fa-2x"></i>
                                          <p>Facebook</p>
                                        </div>
                                      </FacebookShareButton>
                                    </div>
                                    <div className="gol-lg4 col-md-6 col-sm-12">
                                      <TwitterShareButton
                                        url={`${shareMovie}/${getMovieData._id}`}
                                        title={`${getMovieData.title}`}
                                      >
                                        <div className="icon-block">
                                          <i className="social-icon fab fa-twitter fa-2x"></i>
                                          <p>Twitter</p>
                                        </div>
                                      </TwitterShareButton>
                                    </div>
                                    <div className="gol-lg4 col-md-6 col-sm-12">
                                      <TelegramShareButton
                                        url={`${shareMovie}/${getMovieData._id}`}
                                        title={`${getMovieData.title}`}
                                      >
                                        <div className="icon-block">
                                          <i className="social-icon fab fa-telegram fa-2x"></i>
                                          <p>Telegram</p>
                                        </div>
                                      </TelegramShareButton>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="related-movies justify-content-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="block-title">
                      {commentData ? commentData.length : ""}
                      &nbsp;Comments
                    </h2>
                  </div>
                  <div className="col-lg-12">
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                      <div className="row">
                        <div className="col-md-12">
                          <input
                            type="hidden"
                            {...setValue("_id", movieId.movieId, {
                              shouldTouch: true,
                            })}
                          />
                          <div className="form-group">
                            <textarea
                              className="form-control form-control-name"
                              {...register("comment")}
                              type="text"
                              placeholder="Enter your Comment"
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              {...setValue("rating", ratingValue)}
                              className="form-control"
                              type="hidden"
                            />
                            <div className="star-rating">
                              {[...Array(5)].map((star, index) => {
                                index += 1;
                                return (
                                  <button
                                    type="button"
                                    key={index}
                                    className={
                                      index <= ratingValue ? "on" : "off"
                                    }
                                    onClick={() => setRating(index.toString())}
                                    // {...register('rating')}
                                  >
                                    <span
                                      className="star"
                                      style={{
                                        fontSize: "36px",
                                      }}
                                    >
                                      &#9733;
                                    </span>
                                  </button>
                                );
                              })}
                            </div>
                            <label>Rating</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group d-flex align-items-center">
                        <button
                          type="submit"
                          className="hvr-sweep-to-right btn btn-primary"
                        >
                          {commentMutateLoading ? "Loading" : "Submit"}
                        </button>
                        {/* <a
                          href="#"
                          className="hvr-sweep-to-right btn btn-primary ml-3"
                        >
                          Cancel
                        </a> */}
                      </div>
                    </form>
                  </div>
                  <div className="row m-4">
                    <div className="form-group">
                      {moreComment === false
                        ? commentData.slice(0, 2).map((comments) => {
                            return (
                              <div key={comments._id}>
                                <h5 className="text-secondary">
                                  {comments.name}
                                </h5>
                                <p className="ml-4">{comments.comment}</p>
                                {/* <p className="ml-5">See More</p> */}
                              </div>
                            );
                          })
                        : commentData.map((comments) => {
                            return (
                              <div key={comments._id}>
                                <h5 className="text-secondary">
                                  {comments.name}
                                </h5>
                                <p className="ml-4">{comments.comment}</p>
                                {/* <p className="ml-5">See More</p> */}
                              </div>
                            );
                          })}
                      {commentData.length > 0 && (
                        <h5 onClick={() => moreCommentChange()}>
                          {moreComment ? "Read Less" : "Read More"}
                        </h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row col-lg-12">
                  <h2 className="block-title">Related Movies</h2>
                </div>
                <div className="row col-lg-12">
                  {movieData.data &&
                    movieData.data.related.map((related) => {
                      return (
                        <div key={related._id} className="col-6">
                          <div className="video-block">
                            <div className="video-thumb thumb-overlay">
                              <a href="#">
                                <img
                                  className="img-fluid"
                                  src={`${imagePath}/${related.thumbnail}`}
                                  alt=""
                                />
                              </a>
                              <div className="box-content">
                                <ul className="icon">
                                  <li>
                                    <Link to={`/movie/${related._id}`}>
                                      <i className="fas fa-play"></i>
                                    </Link>
                                  </li>
                                  {/* <li>
                                  <a href="#">
                                    <i className="fas fa-plus"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="movie-single.html">
                                    <i className="fas fa-info"></i>
                                  </a>
                                </li> */}
                                </ul>
                              </div>
                            </div>
                            <div className="video-content">
                              <h2 className="video-title">
                                <a href="movie-single.html">{related.title}</a>
                              </h2>
                              <div className="video-info d-flex align-items-center">
                                <span className="video-year">
                                  {moment(related.createdAt).format("L")}
                                </span>
                                {/* <span className="video-age">+18</span> */}
                                {/* <span className="video-type">{related.genre}</span> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default MovieDetail;
