import React, { useState, useContext } from 'react'
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthService from '../../services/AuthService';
import { UserContext } from "../../App";
import axios from 'axios';

const ResetPassword = () => {
    const queryClient = useQueryClient();
    let [params] = useSearchParams();
    let tokenData = '';
    tokenData = params.get("token");

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required('New Password is required'),
        passwordConfirm: Yup.string()
            .required('Confirm Password is required')
            .oneOf([Yup.ref('newPassword')], 'Passwords does not match'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema)
    });

    //Reset Password
    const resetPassword = async ({ newPassword }) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_SERVER}/customer/reset-password`, { newPassword },
                {
                    headers: {
                        Authorization: 'Bearer ' + tokenData
                    }
                }
            );
            console.log(response);
            toast.success('Pasword updated Successfully', {
                // theme: "colored",
            });
            return response;
        }
        catch (error) {
            const errorMessage = error.response.data.message;
            toast.error(errorMessage, {
                // theme: "colored",
            });
            throw new Error();;
        }
    }

    const { mutateAsync: resetPasswordMutate, isLoading: resetPasswordLoading, isError: resetPasswordError, status } =
        useMutation(resetPassword,
            {
                onSuccess: (response) => {
                    // queryClient.invalidateQueries("getAllMovies");
                    console.log(response)
                    // window.location.reload();
                },
                onError: (error) => {
                    console.log(error)
                }
            }
        );


    const onSubmit = (data) => {
        console.log(data)
        resetPasswordMutate(data)
    }


    // console.log(tokenData)

    return (
        <div>
            <div style={{ backgroundColor: 'black' }}>
                <div>
                    <ToastContainer />
                </div>
                <div className="main-content">
                    <section className="login">
                        <div className="container">
                            <div className="row text-center intro">
                                <div className="col-12">
                                    <h2>Reset Password</h2>
                                    <hr />
                                    {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 mt-4">
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="error-container"></div>
                                        <div className="form-group">
                                            <label className="control-label col-xs-4">New Password</label>
                                            <input type="text" {...register('newPassword')} className="form-control" />
                                            <span className='text-danger'>{errors.newPassword?.message}</span>
                                        </div>
                                        <div className="form-group">
                        <label>Confirm Password</label>
                        <div className="input-group flex-nowrap">
                          <input {...register('passwordConfirm')} type="text" className="form-control" autoComplete='none' />
                        </div>
                        <span className='text-danger'>{errors.passwordConfirm?.message}</span>
                      </div>

                                        <div className="form-group">
                                            <button type="submit" className="btn btn-block hvr-sweep-to-right btn-primary btn-lg">{resetPasswordLoading ? 'Loading...' : 'Submit'}</button>
                                        </div>
                                    </form>
                                    <div className="row d-flex align-items-center mb-4">
                                        <div className='col-12'>
                                            <h5 className="text-center">Do have an account?
                                                &nbsp; <Link to='/signin' style={{ textDecoration: 'none', color: 'red' }}>Login Here</Link>
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6" style={{ marginTop: '-10%' }}>
                                    <img className="img-fluid" src="/assets/logo.3113682a.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword