import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const Loading = () => {
    return(
        <div className={"container mt-5"}>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <p>
                    <Skeleton count={3} />
                </p>
            </SkeletonTheme>

            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <p>
                    <Skeleton count={5} />
                </p>
            </SkeletonTheme>
            <SkeletonTheme baseColor="#202020" highlightColor="#444">
                <p>
                    <Skeleton count={10} />
                </p>
            </SkeletonTheme>
        </div>
    )
}
export default Loading;