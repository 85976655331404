import React from "react";

const FrequentlyAskedQuestion = () => {
  const [iconOne, setIconOne] = React.useState(false);
  const [iconTwo, setIconTwo] = React.useState(false);
  const [iconThree, setIconThree] = React.useState(false);
  const [iconFour, setIconFour] = React.useState(false);
  const [iconFive, setIconFive] = React.useState(false);
  const [iconSix, setIconSix] = React.useState(false);

  const iconOneHandleChange = () => {
    iconOne === true ? setIconOne(false) : setIconOne(true);
  };
  const iconTwoHandleChange = () => {
    iconTwo === true ? setIconTwo(false) : setIconTwo(true);
  };
  const iconThreeHandleChange = () => {
    iconThree === true ? setIconThree(false) : setIconThree(true);
  };
  const iconFourHandleChange = () => {
    iconFour === true ? setIconFour(false) : setIconFour(true);
  };
  const iconFiveHandleChange = () => {
    iconFive === true ? setIconFive(false) : setIconFive(true);
  };
  const iconSixHandleChange = () => {
    iconSix === true ? setIconSix(false) : setIconSix(true);
  };

  return (
    <>
      {/* For Large Devices */}
      <div
        className="container-fluid bg-black d-lg-flex d-none justify-content-center"
        style={{ backgroundColor: "black" }}
      >
        <div className="row w-75">
          <div
            class="accordion w-100"
            id="accordionExample"
            style={{ backgroundColor: "#303030" }}
          >
            <div class="accordion-item p-2">
              <h2 class="accordion-header" id="headingOne">
                <div className="row d-flex justify-content-between">
                  <button
                    class="accordion-button p-3 m-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    onClick={iconOneHandleChange}
                  >
                    What is EtNet?
                  </button>
                  <i
                    onClick={iconOneHandleChange}
                    className={
                      iconOne === false
                        ? "fas fa-plus p-3 text-white"
                        : "fas fa-times p-3 text-white"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </div>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "24px" }} class="accordion-header">
                    EtNet is a streaming service that offers a wide variety of
                    Ethiopian films, series and more. For a flat monthly
                    subscription fee, you can stream an unlimited number of
                    movies, anytime, without ads.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
          <div
            class="accordion w-100 mt-2"
            id="accordionExample"
            style={{ backgroundColor: "#303030" }}
          >
            <div class="accordion-item p-2">
              <h2 class="accordion-header" id="headingTwo">
                <div className="row d-flex justify-content-between">
                  <button
                    class="accordion-button p-3 m-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                    onClick={iconTwoHandleChange}
                  >
                    How much does EtNet cost?
                  </button>
                  <i
                    onClick={iconTwoHandleChange}
                    className={
                      iconTwo === false
                        ? "fas fa-plus p-3 text-white"
                        : "fas fa-times p-3 text-white"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </div>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "24px" }} class="accordion-header">
                    Watch EtNet on your smartphone, tablet, Smart TV, laptop, or
                    streaming device, all for one fixed monthly fee. Plans range
                    from 40 birr $6 a month. No extra costs.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div
            class="accordion w-100 mt-2"
            id="accordionExample"
            style={{ backgroundColor: "#303030" }}
          >
            <div class="accordion-item p-2">
              <h2 class="accordion-header" id="headingThree">
                <div className="row d-flex justify-content-between">
                  <button
                    class="accordion-button p-3 m-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseThree"
                    onClick={iconThreeHandleChange}
                  >
                    How can I pay for my subscription?
                  </button>
                  <i
                    onClick={iconThreeHandleChange}
                    className={
                      iconThree === false
                        ? "fas fa-plus p-3 text-white"
                        : "fas fa-times p-3 text-white"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </div>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "24px" }} class="accordion-header">
                    In Ethiopia, you can pay using all currently available
                    mobile wallets (CBE Birr, Amole, Hello Cash and others) We
                    also accept Paypal payments and all major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div
            class="accordion w-100 mt-2"
            id="accordionExample"
            style={{ backgroundColor: "#303030" }}
          >
            <div class="accordion-item p-2">
              <h2 class="accordion-header" id="headingFour">
                <div className="row d-flex justify-content-between">
                  <button
                    class="accordion-button p-3 m-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseFour"
                    onClick={iconFourHandleChange}
                  >
                    How do I cancel?
                  </button>
                  <i
                    onClick={iconFourHandleChange}
                    className={
                      iconFour === false
                        ? "fas fa-plus p-3 text-white"
                        : "fas fa-times p-3 text-white"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </div>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "24px" }} class="accordion-header">
                    EtNet is flexible. There are no pesky contracts and no
                    commitments. You can easily cancel your account online in
                    two clicks. There are no cancellation fees – start or stop
                    your account anytime.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div
            class="accordion w-100 mt-2 mb-5"
            id="accordionExample"
            style={{ backgroundColor: "#303030" }}
          >
            <div class="accordion-item p-2">
              <h2 class="accordion-header" id="headingFive">
                <div className="row d-flex justify-content-between">
                  <button
                    class="accordion-button p-3 m-1 text-white"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseFive"
                    onClick={iconFiveHandleChange}
                  >
                    What can I watch on EtNet?
                  </button>
                  <i
                    onClick={iconFiveHandleChange}
                    className={
                      iconFive === false
                        ? "fas fa-plus p-3 text-white"
                        : "fas fa-times p-3 text-white"
                    }
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  ></i>
                </div>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "24px" }} class="accordion-header">
                    EtNet has an extensive library of feature films,
                    documentaries, TV shows. EtNet originals, and more. Watch as
                    much as you want, anytime you want.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
        </div>
      </div>

      {/* For Small Device */}
      <div
        className="container-fluid bg-black d-lg-none d-block"
        style={{ backgroundColor: "black" }}
      >
        <div
          class="accordion w-100"
          id="accordionExample"
          style={{ backgroundColor: "#303030" }}
        >
          <div class="accordion-item w-100 mb-3">
            <h5
              style={{ fontSize: "19px" }}
              class="accordion-header"
              id="headingOne"
            >
              <div
                className="row d-flex justify-content-between"
                style={{ backgroundColor: "#303030" }}
              >
                <button
                  class="accordion-button p-3 m-1 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                  onClick={iconOneHandleChange}
                >
                  What is EtNet?
                </button>
                <i
                  onClick={iconOneHandleChange}
                  className={
                    iconOne === false
                      ? "fas fa-plus p-3 text-white"
                      : "fas fa-times p-3 text-white"
                  }
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></i>
              </div>
            </h5>

            <div
              className="row"
              style={{ backgroundColor: "#303030", marginTop: "-15px" }}
            >
              <div
                id="collapseOne"
                class="accordion-collapse collapse accordion-body-sm"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
                style={{ width: "100%" }}
              >
                {/* <hr className="text-black"/>  */}
                <div class="accordion-body ">
                  <p style={{ fontSize: "18px" }} class="accordion-header p-3">
                    EtNet is a streaming service that offers a wide variety of
                    Ethiopian films, series and more. For a flat monthly
                    subscription fee, you can stream an unlimited number of
                    movies, anytime, without ads.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        <div
          class="accordion w-100 ps-2 pe-2"
          id="accordionExample"
          style={{ backgroundColor: "#303030" }}
        >
          <div class="accordion-item w-100 mb-3">
            <h5
              style={{ fontSize: "19px" }}
              class="accordion-header"
              id="headingOne"
            >
              <div
                className="row d-flex justify-content-between"
                style={{ backgroundColor: "#303030" }}
              >
                <button
                  class="accordion-button p-3 m-1 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseTwo"
                  onClick={iconTwoHandleChange}
                >
                  How much does EtNet cost?
                </button>
                <i
                  onClick={iconTwoHandleChange}
                  className={
                    iconTwo === false
                      ? "fas fa-plus p-3 text-white"
                      : "fas fa-times p-3 text-white"
                  }
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></i>
              </div>
            </h5>

            <div
              className="row"
              style={{ backgroundColor: "#303030", marginTop: "-15px" }}
            >
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "18px" }} class="accordion-header p-3">
                    Watch EtNet on your smartphone, tablet, Smart TV, laptop, or
                    streaming device, all for one fixed monthly fee. Plans range
                    from 40 birr $6 a month. No extra costs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div
          class="accordion w-100 ps-2 pe-2"
          id="accordionExample"
          style={{ backgroundColor: "#303030" }}
        >
          <div class="accordion-item w-100 mb-3">
            <h5
              style={{ fontSize: "19px" }}
              class="accordion-header"
              id="headingOne"
            >
              <div
                className="row d-flex justify-content-between"
                style={{ backgroundColor: "#303030" }}
              >
                <button
                  class="accordion-button p-3 m-1 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseThree"
                  onClick={iconThreeHandleChange}
                >
                  How can I pay for my subscription?
                </button>
                <i
                  onClick={iconThreeHandleChange}
                  className={
                    iconThree === false
                      ? "fas fa-plus p-3 text-white"
                      : "fas fa-times p-3 text-white"
                  }
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></i>
              </div>
            </h5>

            <div
              className="row"
              style={{ backgroundColor: "#303030", marginTop: "-15px" }}
            >
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "18px" }} class="accordion-header p-3">
                    In Ethiopia, you can pay using all currently available
                    mobile wallets (CBE Birr, Amole, Hello Cash and others) We
                    also accept Paypal payments and all major credit cards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div
          class="accordion w-100 ps-2 pe-2"
          id="accordionExample"
          style={{ backgroundColor: "#303030" }}
        >
          <div class="accordion-item w-100 mb-3">
            <h5
              style={{ fontSize: "19px" }}
              class="accordion-header"
              id="headingOne"
            >
              <div
                className="row d-flex justify-content-between"
                style={{ backgroundColor: "#303030" }}
              >
                <button
                  class="accordion-button p-3 m-1 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseFour"
                  onClick={iconFourHandleChange}
                >
                  How do I cancel?
                </button>
                <i
                  onClick={iconFourHandleChange}
                  className={
                    iconFour === false
                      ? "fas fa-plus p-3 text-white"
                      : "fas fa-times p-3 text-white"
                  }
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></i>
              </div>
            </h5>

            <div
              className="row"
              style={{ backgroundColor: "#303030", marginTop: "-15px" }}
            >
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p style={{ fontSize: "18px" }} class="accordion-header p-3">
                    EtNet is flexible. There are no pesky contracts and no
                    commitments. You can easily cancel your account online in
                    two clicks. There are no cancellation fees – start or stop
                    your account anytime.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
        <div
          class="accordion w-100 ps-2 pe-2"
          id="accordionExample"
          style={{ backgroundColor: "#303030" }}
        >
          <div class="accordion-item w-100 mb-5">
            <h5
              style={{ fontSize: "19px" }}
              class="accordion-header"
              id="headingOne"
            >
              <div
                className="row d-flex justify-content-between"
                style={{ backgroundColor: "#303030" }}
              >
                <button
                  class="accordion-button p-3 m-1 text-white"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseFive"
                  onClick={iconFiveHandleChange}
                >
                  What can I watch on EtNet?
                </button>
                <i
                  onClick={iconFiveHandleChange}
                  className={
                    iconFive === false
                      ? "fas fa-plus p-3 text-white"
                      : "fas fa-times p-3 text-white"
                  }
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                ></i>
              </div>
            </h5>

            <div
              className="row"
              style={{ backgroundColor: "#303030", marginTop: "-15px" }}
            >
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingFive"
                data-bs-parent="#accordionExample"
                style={{ backgroundColor: "#303030" }}
              >
                <div
                  class="accordion-body"
                  style={{ backgroundColor: "#303030" }}
                >
                  <p style={{ fontSize: "18px" }} class="accordion-header p-3">
                    EtNet has an extensive library of feature films,
                    documentaries, TV shows. EtNet originals, and more. Watch as
                    much as you want, anytime you want.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  */}
      </div>
    </>
  );
};

export default FrequentlyAskedQuestion;
