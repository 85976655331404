import React from "react";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <div>
      <div className="main-footer">
        <div className="container">
          <div className="row justify-content-lg-between"  style={{ marginTop: '-50px'}}>
            <div className="col-md-3 col-6">
              <div className="footer-widget">
                <div className="widget">
                  <div className="widget-content footer-menu">
                    <div className="footer-logo">
                      <Link to="/">
                        <img
                          src="../../assets/logo.3113682a.png"
                          alt="logo"
                          className="footer-image"
                          // style={{ he}}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-6 mt-sm-3">
              <div className="footer-widget">
                <div className="widget-content">
                  <div className="footer-about-text">
                    <h4>Etnet</h4>
                    <p className="text-white">
                    EtNet is a streaming service that offers a wide variety of Ethiopian films, series and more. For a flat monthly subscription fee, you can stream an unlimited number of movies, anytime, without ads.
                    </p>
                  </div>
                  <div className="footer-social-icons">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <a href="#" title="twitter">
                          <i className="fab fa-2x fa-twitter"></i>
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a href="https://m.facebook.com/story.php?story_fbid=341115341377861&id=100064381941422" title="facebook">
                          <i className="fab fa-2x fa-facebook-f"></i>
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a href="#" title="instagram">
                          <i className="fab fa-2x fa-instagram"></i>
                        </a>
                      </li>

                      <li className="list-inline-item">
                        <a href="https://www.youtube.com/channel/UCxrwV69SPIbhskEcTAG1mfg" title="youtube">
                          <i className="fab fa-2x fa-youtube"></i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://t.me/+Q7jlL39lAjG_kgce" title="youtube">
                          <i className="fab fa-2x fa-telegram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4 p-4">
              <div className="footer-widget">
                <div className="widget">
                  <div className="widget-header">
                    <h2 className="widget-title">Usefully Links</h2>
                  </div>
                  <div className="widget-content footer-menu">
                    <ul className="f-link list-unstyled mb-0">
                      <li>
                        <Link to="/home">Home</Link>
                      </li>
                      <li>
                        <Link to="/movies">Movies</Link>
                      </li>
                      <li>
                        <Link to="/watch-list">Wish List</Link>
                      </li>
                      <li>
                        <Link to="/setting">Account</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>

      <div className="copyright">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-md-6">
              <p>© Copyright 2021, All Rights Reserved</p>
            </div>

            <div className="col-md-6">
              <div className="copyright-menu text-right">
                <ul>
                  <li>
                    <Link to="/about" href="">About</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" href="">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="back-to-top-btn">
        <div className="back-to-top" style={{ display: "block" }}>
          <a aria-hidden="true" className="fas fa-angle-up" href="#"></a>
        </div>
      </div>
    </div>
  );
};
