import React from "react";

export const Contact = () => {
  return (
    <div>
      <div class="sub-header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav aria-label="breadcrumb" class="text-center breadcrumb-nav">
                <h2 class="Page-title">Contact Us</h2>
                <ol class="breadcrumb">
                  <li>
                    <i class="fa fa-home"></i>
                    <a href="#">Home</a>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a href="#">Contact Us</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <section class="contact-us">
          <div class="container">
            <div class="row text-center intro">
              <div class="col-12">
                <h2>How Can We Help?</h2>
                <p class="text-max-800">
                  Talk to one of our consultants today and learn how to start
                  leveraging your business.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <form id="contact-form" action="contact-form.php" method="post">
                  <div class="error-container"></div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Name</label>
                        <input
                          class="form-control form-control-name"
                          name="visitor_name"
                          id="name"
                          placeholder=""
                          type="text"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Email</label>
                        <input
                          class="form-control form-control-email"
                          name="visitor_email"
                          id="email"
                          placeholder=""
                          type="email"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label>Subject</label>
                        <input
                          class="form-control form-control-title"
                          name="email_title"
                          id="title"
                          placeholder=""
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Message</label>
                    <textarea
                      class="form-control form-control-message"
                      name="visitor_message"
                      id="message"
                      placeholder=""
                      rows="10"
                      required
                    ></textarea>
                  </div>
                  <div>
                    <button class="btn hvr-sweep-to-right" type="submit">
                      Send Message
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
