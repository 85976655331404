import React from "react";
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import AuthService from "../../services/AuthService";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from "react-router-dom";

const AddUser = () => {

    const queryClient = useQueryClient();


    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Name is required'),
        category: Yup.string().required('Category is required'),
        // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        //   defaultValues,
        resolver: yupResolver(validationSchema)
    });



    //Add New User
    const { mutateAsync: addUserMutate, isLoading: addUserLoading } = useMutation(
        "addUser",
        AuthService.addUser,
        {
            onSuccess: () => {
                queryClient.invalidateQueries("customerProfile")
            }
        }
    )


    const onFormSubmit = (defaultValues) => {
        console.log(defaultValues)
        addUserMutate(defaultValues)
    }

   

    return (
        <div style={{ backgroundColor: 'black' }}>
            <div className="row d-flex justify-content-center align-items-center m-5">
                <div className="col-lg-6 mt-4">
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <div className="error-container"></div>
                        <div className="form-group">
                            <label className="control-label col-xs-4">Username</label>
                            <input type="text" {...register('title')} className="form-control" />
                            <span className='text-danger'>{errors.title?.message}</span>
                        </div>
                        <div className="form-group">
                            <label className="control-label col-xs-4">Category</label>
                            {/* <input type="text" {...register('category')} className="form-control" /> */}
                            <select {...register('category')} className="form-control">
                                <option value={'adult'}>Adult</option>
                                <option value={'child'}>Child</option>
                            </select>
                            <span className='text-danger'>{errors.category?.message}</span>
                        </div>
                        <div className="form-group">
                            <button className="btn btn-danger btn-sm m-1" type="submit" >{addUserLoading ? 'Loading...' : 'Submit'}</button>
                           <Link to='/setting'><button className="btn m-1" style={{ backgroundColor: 'gray'}}>Back</button></Link> 
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default AddUser