import React from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient, useQuery } from "react-query";
import AuthService from "../../services/AuthService";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link } from "react-router-dom";

const UpdateProfile = (defaultValues) => {
  const queryClient = useQueryClient();
  const newDefaultValues = defaultValues.defaultValues;
  console.log(defaultValues.defaultValues);
  
  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    phone: Yup.string().required('Phone is required'),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const { mutateAsync: updateProfileMutate, isLoading: updateProfileLoading } =
    useMutation("updateProfile", AuthService.updateProfile, {
      onSuccess: () => {
        queryClient.invalidateQueries("customerProfile");
      },
    });
  console.log(newDefaultValues);
  const onFormSubmit = (newDefaultValues) => {
    console.log(newDefaultValues);
    updateProfileMutate(newDefaultValues);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="error-container"></div>
        <div className="row">
          <div className="col-md-2">
            <div className="form-group">
              <div className="settings-avatar">
                <img
                  src="assets/images/avatar/user.jpg"
                  className="img-fluid d-block mx-auto mb-3"
                  alt="User avatar"
                />
                {/* <div className="change-avatar">
                                    <a href="#">Edit</a>
                                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <h2>
              {newDefaultValues.firstName} {newDefaultValues.lastName}
            </h2>
            <p>{newDefaultValues.email}</p>
            <p>{newDefaultValues.phone}</p>
          </div>
        </div>
        <h5 className="mb-3 mt-3 pb-3 section-border">Update Information</h5>
        <div className="row">
          <div className="col-md-6">
            <div className="form-group">
              <label>First Name</label>
              <input
                className="form-control form-control-name"
                name="first-name"
                id="first-name"
                type="text"
                // placeholder="Enter your First Name"
                defaultValue={newDefaultValues.firstName}
                {...register("firstName")}
                // required
              />
               <span className='text-danger'>{errors.firstName?.message}</span>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group">
              <label>Last Name</label>
              <input
                className="form-control form-control-name"
                name="last-name"
                id="last-name"
                type="text"
                placeholder="Enter your Last Name"
                defaultValue={newDefaultValues.lastName}
                {...register("lastName")}
                // required
              />
               <span className='text-danger'>{errors.lastName?.message}</span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label>Phone</label>
              <input
                className="form-control form-control-name"
                name="last-name"
                id="last-name"
                type="number"
                placeholder="Enter your Phone"
                defaultValue={newDefaultValues.phone}
                {...register("phone")}
                // required
              />
               <span className='text-danger'>{errors.phone?.message}</span>
            </div>
          </div>

          <div className="row col-12 m-1 form-group d-flex align-items-center mt-3">
            <button
              type="submit"
              className="hvr-sweep-to-right btn btn-primary"
            >
              {updateProfileLoading ? "Loading..." : "Save"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProfile;
