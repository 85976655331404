import firebase from 'firebase/app'
import 'firebase/auth'

var firebaseConfig = {
  apiKey: "AIzaSyBsU0RtUA0XK2PDR-zXQZl363-BmNDvROw",
authDomain: "etnet-1eaba.firebaseapp.com",
projectId: "etnet-1eaba",
storageBucket: "etnet-1eaba.appspot.com",
messagingSenderId: "132131897614",
appId: "1:132131897614:web:8a9bfa48658351d653d75f",
measurementId: "G-WZ09VH8C7B"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

export default firebase
