import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../services/AuthService";
import { UserContext } from "../../App";
import axios from "axios";
import firebase from "./firebase";

const VerificationWithPhone = () => {
  const [otp, setOtp] = useState();
  var otpData = "";

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    conformation: Yup.string().required("Verification code is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
    "sign-in-button",
    {
      size: "invisible",
      callback: function (response) {
        console.log("It works!", response);
      },
    }
  );
 




  window.confirmationResult = JSON.parse(localStorage.getItem("otpValue"));
  console.log(window.confirmationResult);
  console.log(typeof JSON.parse(localStorage.getItem("otpValue")));
  // const onSubmit = data => console.log(data);
  const onSubmit = (e) => {
    e.preventDefault();
    const code = otp;
    console.log(code);
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("result", result);
        console.log(JSON.stringify(user));
        alert("User is verified");
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
      });
    // ...
  };

  return (
    <div style={{ backgroundColor: "black" }}>
      <div>
        <ToastContainer />
      </div>
      <div id="sign-in-button"></div>
      <div className="main-content">
        <section className="login">
          <div className="container">
            <div className="row text-center intro">
              <div className="col-12">
                <h2>Please Verify Your Account</h2>
                <p>
                  We have sent you a verifcation code to your email, please
                  check out.
                </p>
                <hr />
                {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                <form onSubmit={onSubmit}>
                  <div className="error-container"></div>
                  <div className="form-group">
                    <label className="control-label col-xs-4">
                      Verification Code
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="otp"
                      placeholder="OTP Number"
                      required
                      value={otp}
                      onChange={(e) => {
                        setOtp(e.target.value);
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                    >
                      Verify
                    </button>
                  </div>
                  {/* <a href="reset-password.html">Lost your Password?</a> */}
                </form>
                <h4 className="text-center">
                  Don't have an account?
                  {/* <a href="/signup">Sign up here!</a> */}
                  &nbsp;{" "}
                  <Link to="/signup" style={{ textDecoration: "none" }}>
                    Signup Here
                  </Link>
                </h4>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default VerificationWithPhone;
