import React from 'react'
import { useQuery } from 'react-query'
import AuthService from '../../services/AuthService'
import { Link } from 'react-router-dom'

const ChooseAccount = () => {
  const { data: customerData, isLoading: customerLoading, isFetching: customerFetching, isError: customerError, status } = useQuery(
    "chooseAccount", AuthService.chooseAccount
  )

  console.log(customerData)

  const user = localStorage.getItem('user')
  const userData = JSON.parse(user)
  const userProfiles = user.customer_user
  console.log(userProfiles)
  console.log(status)


  if (customerLoading || status === 'idle' || status === 'loading')
    return (
     <div className="d-flex justify-content-center align-items-center">
        <h3>Loading....</h3>
      </div>
    )

  if (customerFetching)
    return (
     <div className="d-flex justify-content-center align-items-center">
        <h3>Loading....</h3>
      </div>
    )

    if (customerError)
    return (
     <div className="d-flex justify-content-center align-items-center">
        <h3>Something Went Wrong</h3>
      </div>
    )
  // console.log(customerData)
  // const newCustomerData = customerData ? customerData.data : 'Loading'
  // console.log(newCustomerData)

  return (
    <div style={{ backgroundColor: 'black' }}>
      <section className="our-team">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="block-title">ChooseAccount</h2>
            </div>
          </div>
          <div className="row">
            {
              customerData.map(customers => {
                return (
                  
                    <div className="col-lg-3 col-md-4 col-sm-6 col-xm-12" key={customers._id}>
                      <div className="card team-box">
                        <div className="team-box-img">
                        
                        </div>
                        <div className="card-body">
                        <Link to="/home"><h2 className="card-title team-box-name">{customers.title}</h2></Link>  
                          {/* <span>{customers.category}</span> */}
                        </div>
                      </div>
                    </div>
                  
                )
              })
            }
            <div className="col-lg-3 col-md-4 col-sm-6 col-xm-12">
              <div className="card team-box">
                <div className="team-box-img">
                  {/* <img src="images/about/01.jpg" className="img-fluid img-zoom" alt="" /> */}
                </div>
                {/* <div className="card-body">
                  <Link to="/home"><h2 className="card-title team-box-name">{userData.customer_user.firstName}</h2></Link>
                  <span>Adult</span>
                </div> */}
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  )
}

export default ChooseAccount

