import React from 'react'

const LocalAdsComponent =({ id, image, postedDate, onWhishListFormSubmit }) => {
    const imagePath = process.env.REACT_APP_API_SERVER_IMAGE

  return (
    <div className="item" key={id}>
    <div className="video-block">
      <div className="video-thumb position-relative thumb-overlay">
          <img
            alt=""
            className="img-fluid"
            src={`${imagePath}/${image}`}
          />

      </div>

      <div className="video-content">
        {/* <h2 className="video-title">
          <a href={`movie/${id}`}>{title}</a>
        </h2> */}
        <div className="video-info d-flex align-items-center">
          <span className="video-year">{postedDate}</span>{" "}

        </div>
      </div>
    </div>
  </div>
);
};


export default LocalAdsComponent