import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery, useQueryClient, useMutation } from "react-query";
import LatestMoviesComponent from "../components/moviesComponent/LatestMoviesComponent";
import MoviesComponent from "../components/moviesComponent/MoviesComponent";
import SingleMoviesComponent from "../components/moviesComponent/SingleMoviesComponent";
import MovieService from "../services/MovieService";
import { useParams } from "react-router";
import Loading from "../components/shared/Loading";

const ShowsList = () => {
  const queryClient = useQueryClient()
  const movieId = useParams();
  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE;
  let newSeriesMovies = [];

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } = useMutation(
    "addToWhishList",
    MovieService.addToWishList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail")
      }
    })

  const {
    data: seriesMoives,
    isLoading: seriesMoviesLoading,
    isError: seriesMoviesError,
  } = useQuery(["seriesMovies", movieId.movieId], () =>
    MovieService.seriesMovieDetail(movieId.movieId)
  );


  if ( seriesMoviesLoading)
    return (
      <Loading/>
    );
  if (seriesMoviesError) return      <div className="d-flex justify-content-center align-items-center">Something Went Wrong</div>;
  
  //console.log(seriesMoives.data.poster);
  newSeriesMovies = seriesMoives.data.episodes;

 

  const onWhishListFormSubmit = (defaultValues) => {
    //console.log(defaultValues)
    wishListMutate(defaultValues)
  }

  return (
    <div style={{ backgroundColor: "black" }}>
      <section className="trending">
        <div className="container-fluid">
          <h2 className="block-title">
            {seriesMoives.data ? seriesMoives.data.title : ""}
          </h2>
          <div className="row">
            {newSeriesMovies.map((movies) => {
       
              return (
                <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                  <LatestMoviesComponent
                    key={movies._id}
                    id={movies._id}
                    title={movies.title}
                    image={`${imageURL}/${movies.poster ? movies.poster : seriesMoives.data.poster}`}
                    category={movies.category}
                    postedDate={movies.createdAt}
                    views={movies.views}
                    // isLoading={moviesLoading}
                    // onWhishListFormSubmit={onWhishListFormSubmit} 
                  />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShowsList;
