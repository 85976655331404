import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const LatestMoviesComponent = ({
  id,
  title,
  image,
  views,
  category,
  postedDate,
  isLoading,
  onWhishListFormSubmit,
}) => {
  const validationSchema = Yup.object().shape({
    movieId: Yup.string(),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    //   defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <div className="video-block mb-3">
        <div className="video-thumb position-relative thumb-overlay">
        <Link to={`/movie/${id}`}>
            <img
              loading="lazy"
              className="img-fluid"
              src={isLoading ? "" : image}
              alt={""}
            />
          </Link>
          <div className="box-content">
            <ul className="icon">
              <li>
                <Link to={`/movie/${id}`}>
                  <i className="fas fa-play"></i>
                </Link>
              </li>
              <li>
                <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                  <input
                    type="hidden"
                    {...setValue("movieId", id, { shouldTouch: true })}
                  />
                  <a
                    type="submit"
                    onClick={handleSubmit(onWhishListFormSubmit)}
                  >
                    <i className="fas fa-plus text-white"></i>
                  </a>
                </form>
              </li>
              {/* <li>
                                <a href="movie-single.html">
                                    <i className="fas fa-info"></i>
                                </a>
                            </li> */}
            </ul>
          </div>
        </div>

        <div className="video-content">
          <h2 className="video-title">
            <Link to={`/movie/${id}`}>{isLoading ? "loading" : title}</Link>
          </h2>
          <div className="video-info d-flex align-items-center">
            <span className="video-year">
              {isLoading ? "loading" : moment(postedDate).format("YYYY")}
            </span>{" "}
            {/* <span className="video-age">{isLoading ? 'loading' : views}</span>{" "} */}
            <span className="video-type">
              {isLoading ? "loading" : category}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestMoviesComponent;
