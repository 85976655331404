import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

const SliderMovie = ({
  id,
  title,
  description,
  image,
  onWhishListFormSubmit,
  createdAt,
}) => {
  const imagePath = process.env.REACT_APP_API_SERVER_IMAGE;
  const videoPath = process.env.REACT_APP_API_SERVER_VIDEO;

  const validationSchema = Yup.object().shape({
    movieId: Yup.string(),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    //   defaultValues,
    resolver: yupResolver(validationSchema),
  });

  return (
    <div>
      <div
        className="main-slider"
        id="main-slider"
        style={{
          backgroundImage: `url(${imagePath}/${image})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
        }}
      >
        <div className="slider big-slider slider-wrap">
          <div className="item slide slick-bg bg-2">
            <div className="container-fluid position-relative h-100">
              <div className="slider-content h-100">
                <div className="row align-items-center h-100">
                  <div className="col-xl-6 col-lg-12 col-md-12">
                    <h3 data-animation-in="fadeInUp" data-delay-in="1">
                      <span className="badge bg-warning text-dark">New</span>
                    </h3>
                    <h1 data-animation-in="fadeInUp" data-delay-in="1">
                      {title}
                    </h1>
                    <div
                      className="slide-info"
                      data-animation-in="fadeInUp"
                      data-delay-in="1"
                    >
                      <span>{moment.utc(createdAt).format("YYYY")}</span>{" "}
                      <span className="radius">+18</span>{" "}
                      {/* <span>2h 6m</span> */}
                    </div>
                    <p data-animation-in="fadeInUp" data-delay-in="1">
                      {description}
                    </p>
                    <div
                      className="slider-buttons d-flex align-items-center"
                      data-animation-in="fadeInUp"
                      data-delay-in="1"
                    >
                      <Link
                        className="btn hvr-sweep-to-right mr-2"
                        to={`/movie/${id}`}
                        tabIndex="0"
                      >
                        <i aria-hidden="true" className="fa fa-play mr-2"></i>
                        Play Now
                      </Link>{" "}
                      <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                        <input
                          type="hidden"
                          {...setValue("movieId", id, { shouldTouch: true })}
                        />
                        <button className="btn btn-danger" type="submit">
                          <i className="fas fa-plus text-white"></i>
                          My List
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderMovie;
