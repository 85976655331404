import React from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router";
import moment from "moment";
import MovieService from "../../services/MovieService";
import MoviesComponent from "../moviesComponent/MoviesComponent";
import Loading from "./Loading";

export const Wishlist = () => {

  const { data: wishListData, isLoading: wishListLoading, isError: wishListError } = useQuery('getWishList',
    MovieService.getWishLists
  )

  const queryClient = useQueryClient()

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } = useMutation(
    "addToWhishList",
    MovieService.removeFromWishList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getWishList")
      }
    })

  const onWhishListFormSubmit = (defaultValues) => {
    // console.log(defaultValues)
    wishListMutate(defaultValues)
  }

  if (wishListLoading)
    return (
     <Loading/>
    )

    if(wishListError)
    return(
      <div className="d-flex justify-content-center align-items-center">
        <h3>Something Went Wrong</h3>
      </div>
    )

  const icon = "fas fa-minus text-white"

  const movieData = wishListData
  console.log(movieData)

  return (
    <div style={{ backgroundColor: 'black' }}>
      <section className="trending">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="block-title">WishList</h2>
              <div className="row">
                {
                  movieData &&
                  movieData.map(movie => {
                    return (
                    
                        <MoviesComponent key={movie._id} id={movie._id} title={movie.title} image={movie.poster} genre={movie.genre} postedDate={moment.utc(movie.createdAt).format('YYYY')} onWhishListFormSubmit={onWhishListFormSubmit} icon={icon} isMutateLoading={wishListMutateLoading} />
                      
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
