import axios from "axios";
import { toast } from 'react-toastify';

const token = localStorage.getItem("token")

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: " http://localhost:3004",
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  },
});


const localAds = async () => {
    try {
        const response = await apiClient.get('/web/adverts');
        return response;
    }
    catch (error) {
        console.log(error);
        throw new Error();
    }
}

const AdsService = {
    localAds
}

export default AdsService;