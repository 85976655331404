import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery, useQueryClient, useMutation } from "react-query";
import LatestMoviesComponent from "../components/moviesComponent/LatestMoviesComponent";
import MoviesComponent from "../components/moviesComponent/MoviesComponent";
import SingleMoviesComponent from "../components/moviesComponent/SingleMoviesComponent";
import MovieService from "../services/MovieService";
import Loading from "../components/shared/Loading";

export const Movies = () => {
  const queryClient = useQueryClient()
  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE

  const { data: popularMovies, isLoading: popularMoviesLoading, isError: popularMoviesError } = useQuery('popularMovies', MovieService.popularMovies);
  const { data: allMovies, isLoading: allMoviesLoading, isError: allMoviesError } = useQuery('allMovies', MovieService.singleMovies);
  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } = useMutation(
    "addToWhishList",
    MovieService.addToWishList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail")
      }
    })

  const onWhishListFormSubmit = (defaultValues) => {
    console.log(defaultValues)
    wishListMutate(defaultValues)
  }
  // console.log(popularMoviesLoading ? 'Looding' : popularMovies)
  // console.log(newPopularMovies.data)

  if (popularMoviesLoading)
    return (
        <Loading/>
    )
  if (popularMoviesError)
    return (
        <div className="d-flex justify-content-center align-items-center">
        Something Went Wrong
      </div>
    )
  const newPopularMovies = popularMovies.data
  console.log(newPopularMovies)


  return (
    <div style={{ backgroundColor: 'black' }}>
      <section className="trending">
        <div className="container-fluid">
          <h2 className="block-title">Movies</h2>
          <div className="row">

            {
              newPopularMovies.map((movies) => {
                return (
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                    <LatestMoviesComponent
                      key={movies.id}
                      id={movies._id}
                      title={movies.title}
                      image={`${imageURL}/${movies.poster}`}
                      category={movies.category}
                      postedDate={movies.createdAt}
                      views={movies.views}
                      onWhishListFormSubmit={onWhishListFormSubmit}   
                    // isLoading={moviesLoading}
                    />
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>
    </div>

  );
};
