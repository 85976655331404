import React, {useContext, useState} from "react";
import moment from "moment";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Link, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import {UserContext} from "../../App";
import GenreService from "../../services/GenreService";
import MovieService from "../../services/MovieService";
import Loading from "./Loading";

const Search = (data) => {
    const imageURL = process.env.REACT_APP_API_SERVER_IMAGE;
    const queryClient = useQueryClient();
    const [getTitle, setGetTitle] = useState("");
    const [getResult, setGetResult] = useState(null);
    const fortmatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };

    const {
        mutateAsync: searchMovieMutate,
        isLoading: searchMovieLoading,
        data: searchData,
        refetch: searchByTitle,
        isError: searchError,
        isFetching: searchFetching,
    } = useMutation("searchMovie", MovieService.searchMovie, {
        enabled: false,
        retry: 1,
        onSuccess: (res) => {
            const result = {
                status: res.status + "-" + res.statusText,
                headers: res.headers,
                data: res.data,
            };
            setGetResult(result);
        },
        onError: (err) => {
            setGetResult(err.response?.data || err);
        },
    });

    React.useEffect(() => {
        if (searchMovieLoading) setGetResult("searching...");
    }, [searchMovieLoading]);

    function getDataByTitle() {
        if (getTitle) {
            try {
                searchByTitle();
                JSON.parse(getResult);

                if (finalData.data === {} || finalData.data === null) {
                    return <div>Error</div>;
                }
                setGetResult(getResult.data);
            } catch (err) {
            }
        }
        // setGetTitle(null)
    }

    const finalData = getResult;
    console.log(finalData);

    const validationSchema = Yup.object().shape({
        search: Yup.string(),
        // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        formState: {errors},
    } = useForm({
        //   defaultValues,
        resolver: yupResolver(validationSchema),
    });

    // console.log(genreLoading ? "Loading" : genreData)

    const onFormSubmit = (defaultValues) => {
        console.log(defaultValues);
        searchMovieMutate(defaultValues);
    };

    // React.useEffect(() => {
    //     if (searchMovieLoading)
    //         return (
    //             <div>
    //                 <h3>Loading...</h3>
    //             </div>
    //         )

    //     if (searchFetching)
    //         return (
    //             <div>
    //                 <h3>Loading...</h3>
    //             </div>
    //         )

    //     console.log(getResult ? getResult.data : [])

    // })

    if (searchMovieLoading)
        return (
            <Loading/>
        );

    if (searchFetching)
        return (
            <Loading/>
        );

    const newGetResult = getResult ? getResult.data : [];
    console.log(newGetResult);

    // if (newGetResult.length === 0)
    //     return (
    //         <div>
    //             <h3>No Data Found</h3>
    //         </div>
    //     )

    return (
        <div style={{backgroundColor: "black", marginTop: "-25px"}}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="container">
                    <br/>
                    <h2 className="block-title mt-5">Search Movies</h2>
                    <div className="row">
                        <div className="col-lg-9 col-md-9 mt-1">
                            <input
                                className="form-control"
                                {...register("title")}
                                placeholder="Search Here"
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 mt-1">
                            <button type="submit" className="btn btn-sm btn-danger">
                                Search
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <section className="trending">
                <hr/>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="row">
                                {newGetResult ? (
                                    newGetResult !== [] ? (
                                        newGetResult.map((movie) => {
                                            return (
                                                <>
                                                    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                                                        <div className="video-block">
                                                            <div
                                                                className="video-thumb position-relative thumb-overlay">
                                                                <Link to={`/movie/${movie._id}`}>
                                                                    <img
                                                                        alt=""
                                                                        className="img-fluid"
                                                                        src={`${imageURL}/${movie.poster}`}
                                                                    />
                                                                </Link>
                                                                <div className="box-content">
                                                                    <ul className="icon">
                                                                        <li>
                                                                            <Link to={`/movie/${movie._id}`}>
                                                                                <i className="fas fa-play"></i>
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            {/* <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                                              <input type="hidden" {...setValue('movieId', id, { shouldTouch: true })} />
                                              <button type='submit'>
                                                <i className={icon}></i>
                                              </button>
                                            </form> */}
                                                                        </li>
                                                                        {/* <li>
                                              <a href={`movie/${id}`}>
                                                <i className="fas fa-info"></i>
                                              </a>
                                            </li> */}
                                                                    </ul>
                                                                </div>
                                                            </div>

                                                            <div className="video-content">
                                                                <h2 className="video-title">
                                                                    <Link to={`/movie/${movie._id}`}>
                                                                        {movie.title}
                                                                    </Link>
                                                                </h2>
                                                                <div className="video-info d-flex align-items-center">
                                                                    {/* <span className="video-year">{movie.createdAt}</span>{" "} */}
                                                                    {/* <span className="video-age">+18</span>{" "} */}
                                                                    {/* <span className="video-type">{genre}</span> */}
                                                                </div>
                                                                {/* <p>{isMutateLoading ? "Loading..." : ""}</p> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })
                                    ) : (
                                        <h3 className="text-center">No Data Found</h3>
                                    )
                                ) : newGetResult && newGetResult.length === 0 ? (
                                    <h3 className="text-center">No Data Found</h3>
                                ) : (
                                    <h3 className="text-center">No Data Found</h3>
                                )}
                                {newGetResult && newGetResult.length === 0 ? (
                                    <h3 className="text-center">No Data Found</h3>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Search;
