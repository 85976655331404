import React from "react";
import Video from "../hooks/Video";

export const ViewMovie = () => {
  const playerRef = React.useRef(null);

  const videoJsOptions = {
    // lookup the options in the docs for more options
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: "https://api.etnet.et/movies/hedar.m3u8",
        type: "application/x-mpegURL",
      },
    ],
  };

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // you can handle player events here
    player.on("waiting", () => {
      console.log("player is waiting");
    });

    player.on("dispose", () => {
      console.log("player will dispose");
    });
  };
  return (
    <div style={{ backgroundColor: "black" }}>
      <div class="video-container ">
        <Video
          options={videoJsOptions}
          onReady={handlePlayerReady}
          style={{ margin: "0px", padding: "0px", height: "200px" }}
        />
      </div>
      <div class="main-content ">
        <section class="play-details">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-12">
                    <div class="play-thumb mb-4">
                      <img
                        class="img-fluid"
                        src="assets/images/play-page/01.jpg"
                        alt=""
                      />
                      <div class="top-badge">
                        <div class="video-badge">
                          <img
                            class="img-fluid"
                            src="assets/images/top-movies.png"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div class="thumb-details text-center">
                      <span> 1080p</span>
                      <span>24p</span>
                      <span>
                        <img
                          class="img-fluid"
                          src="assets/images/dts-logo.png"
                          alt=""
                        />
                      </span>
                      <span>7.1</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="play-details-content">
                  <div class="title-block d-flex align-items-center justify-content-between">
                    <h2 class="play-title">The Dark Knight Rises</h2>
                  </div>
                  <div class="details-info mb-4">
                    <span>
                      <i class="icofont-user mr-2" aria-hidden="true"></i> 18+
                    </span>
                    <span>
                      <i class="icofont-clock-time mr-2" aria-hidden="true"></i>{" "}
                      2hr 45min
                    </span>
                    <span>
                      <i
                        class="icofont-simple-smile mr-2"
                        aria-hidden="true"
                      ></i>{" "}
                      2021
                    </span>
                    <span>
                      <i class="icofont-movie mr-2" aria-hidden="true"></i>{" "}
                      Action
                    </span>
                    <span>
                      <i class="icofont-world mr-2" aria-hidden="true"></i>{" "}
                      United States
                    </span>
                  </div>
                  <div class="details-desc">
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                    </p>
                  </div>
                  <div class="movie-persons mb-4">
                    <div class="person-block">
                      <h5 class="title">Director</h5>
                      <p class="name">Christopher Nolan</p>
                    </div>
                    <div class="person-block">
                      <h5 class="title">Cast</h5>
                      <p>
                        Christian Bale, Michael Cain, Gary Oldman, Anne Hathway,
                        Tom Hardy, Marion Cotillard
                      </p>
                    </div>
                  </div>
                  <div class="details-buttons">
                    <div class="row d-flex align-items-center">
                      <div class="col-6 col-xl mb-xl-0 mb-3">
                        <a
                          href="/play"
                          class="btn d-block hvr-sweep-to-right"
                          tabIndex="0"
                        >
                          <i
                            class="icofont-ui-play mr-2"
                            aria-hidden="true"
                          ></i>
                          Play
                        </a>
                      </div>
                      <div class="col-6 col-xl mb-xl-0 mb-3">
                        <a
                          href="/play"
                          class="btn d-block hvr-sweep-to-right"
                          tabIndex="0"
                        >
                          <i class="icofont-plus mr-2" aria-hidden="true"></i>MY
                          List
                        </a>
                      </div>
                      <div class="col-6 col-xl mb-xl-0">
                        <a
                          id="share"
                          class="btn hvr-sweep-to-right d-block"
                          tabIndex="0"
                          data-toggle="modal"
                          data-target="#share-modal"
                        >
                          <i class="icofont-share mr-2" aria-hidden="true"></i>
                          Share
                        </a>

                        <div
                          class="modal fade"
                          id="share-modal"
                          tabIndex="0"
                          role="dialog"
                          aria-labelledby="share-modal"
                          aria-hidden="true"
                        >
                          <div
                            class="modal-dialog modal-lg"
                            role="document"
                            id="sharemodal"
                          >
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 class="modal-title">Share</h5>
                                <button
                                  type="button"
                                  class="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">
                                    <i class="fas fa-times"></i>
                                  </span>
                                </button>
                              </div>
                              <div class="modal-body">
                                <div class="icon-container d-flex">
                                  <div class="icon-block">
                                    <i class="social-icon fab fa-twitter fa-2x"></i>
                                    <p>Twitter</p>
                                  </div>
                                  <div class="icon-block">
                                    <i class="social-icon fab fa-facebook fa-2x"></i>
                                    <p>Facebook</p>
                                  </div>
                                  <div class="icon-block">
                                    <i class="social-icon fab fa-instagram fa-2x"></i>
                                    <p>Instagram</p>
                                  </div>
                                  <div class="icon-block">
                                    <i class="social-icon fab fa-telegram fa-2x"></i>
                                    <p>Telegram</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="related-movies justify-content-center">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-8">
                <div class="row">
                  <div class="col-lg-6">
                    <h2 class="block-title">Comments</h2>
                  </div>
                  <div class="col-lg-12">
                    <form id="profile-settings-form" action="#" method="post">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <textarea
                              class="form-control form-control-name"
                              name="username"
                              id="username"
                              type="text"
                              placeholder="Enter your Comment"
                              rows="5"
                            ></textarea>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Ratting</label>
                          </div>
                        </div>
                      </div>
                      <div class="form-group d-flex align-items-center">
                        <a href="#" class="hvr-sweep-to-right btn btn-primary">
                          Send
                        </a>
                        <a
                          href="#"
                          class="hvr-sweep-to-right btn btn-primary ml-3"
                        >
                          Cancel
                        </a>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 row justify-content-center">
                <div class="col-lg-12">
                  <h2 class="block-title">Related Movies</h2>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/01.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">life is Beautiful</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/02.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">The End</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/03.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">the beginning</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/04.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">The Search</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/05.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">The Treasures</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class=" col-sm-6 col-md-4 col-lg-4 col-xl-6">
                  <div class="video-block">
                    <div class="video-thumb position-relative thumb-overlay">
                      <a href="#">
                        <img
                          class="img-fluid"
                          src="assets/images/best-action/06.jpg"
                          alt=""
                        />
                      </a>
                      <div class="box-content">
                        <ul class="icon">
                          <li>
                            <a href="/play">
                              <i class="fas fa-play"></i>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <i class="fas fa-plus"></i>
                            </a>
                          </li>
                          <li>
                            <a href="movie-single.html">
                              <i class="fas fa-info"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div class="video-content">
                      <h2 class="video-title">
                        <a href="movie-single.html">Problems</a>
                      </h2>
                      <div class="video-info d-flex align-items-center">
                        <span class="video-year">2021</span>
                        <span class="video-age">+18</span>
                        <span class="video-type">Action</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
