import React from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const PayWithPayPal = () => {
  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <PayPalScriptProvider options={{ "client-id": "test" }}>
            <div className="row">
              <div className="col-lg-12 mt-5">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title text-muted text-uppercase text-center">
                      {/* {prices.name} */} One Month
                    </h5>
                    <p className="card-price text-center">
                      $ 1.99
                      {/* <span className="period">/{prices.months}</span> */}
                    </p>
                    <hr />
                    <ul className="fa-ul">
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        <strong>New Movies</strong>
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        Streamit Special
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        American Tv Shows
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        Hollywood Movies
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        FHD (1080p) Video Quality
                      </li>
                      <li>
                        <span className="fa-li">
                          <i className="fas fa-check"></i>
                        </span>
                        Ad Free Entertainment
                      </li>
                    </ul>
                    <PayPalButtons
                      style={{
                        layout: "horizontal",
                        tagline: "false",
                      }}
                      createOrder={(data, actions) => {
                        return actions.order.create({
                          purchase_units: [
                            {
                              amount: {
                                value: "1.99",
                              },
                            },
                          ],
                        });
                      }}
                      onApprove={(data, actions) => {
                        return actions.order.capture().then((details) => {
                          const name = details.payer.name.given_name;
                          alert(`Transaction completed by ${name}`);
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
};

export default PayWithPayPal;
