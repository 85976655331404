import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';

export const PopularSlid = ({ id, title, description, image, postedDate, onWhishListFormSubmit }) => {
    const imagePath = process.env.REACT_APP_API_SERVER_IMAGE
    const validationSchema = Yup.object().shape({
      movieId: Yup.string()
      // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
    });
  
    const {
      register,
      handleSubmit,
      reset,
      setValue,
      formState: { errors }
    } = useForm({
      //   defaultValues,
      resolver: yupResolver(validationSchema)
    });


  return (
    <div className="item">
      <div className="video-block">
        <div className="video-thumb position-relative thumb-overlay">
          <Link to={`movie/${id}`}>
            <img
              alt=""
              className="img-fluid"
              src={`${imagePath}/${image}`}
            />
          </Link>
          <div className="box-content">
            <ul className="icon">
              <li>
                <Link to={`movie/${id}`}>
                  <i className="fas fa-play"></i>
                </Link>
              </li>
              <li>
              <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                  <input type="hidden" {...setValue('movieId', id, { shouldTouch: true })} />
                  <button type='submit'>
                    <i className="fas fa-plus text-white"></i>
                  </button>
                </form>
              </li>
            </ul>
          </div>
        </div>

        <div className="video-content">
          <h2 className="video-title">
            <Link to={`movie/${id}`}>{title}</Link>
          </h2>
          <div className="video-info d-flex align-items-center">
            <span className="video-year">{postedDate}</span>{" "}
            {/* <span className="video-age">+18</span>{" "}
            <span className="video-type">Action</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};
