import axios from "axios";
import { toast } from "react-toastify";

const token = localStorage.getItem("token")

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: " http://localhost:3004",
  headers: {
    "Content-type": "application/json",
    Authorization: "Bearer " + token,
  },
});

const singleMovies = async () => {
  try {
    const response = await apiClient.get("/web/single-movies");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const popularMovies = async () => {
  try {
    const response = await apiClient.get("/web/popular");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const latestMovies = async () => {
  try {
    const response = await apiClient.get("/web/latest-movies");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const seriesMovies = async () => {
  try {
    const response = await apiClient.get("/web/series-movies");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const seriesMoviesByid = async (id) => {
  try {
    const response = await apiClient.get(`/web/movie/${id}/episodes`);
    return response;
  } catch (error) {
    throw new Error();
  }
};

const latestShows = async () => {
  try {
    const response = await apiClient.get("/web/latest-shows");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const recommendedMovies = async () => {
  try {
    const response = await apiClient.get("/web/recommended");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const slideMovies = async () => {
  try {
    const response = await apiClient.get("/web/slide");
    return response;
  } catch (error) {
    throw new Error();
  }
};

const MovieDetail = async (id) => {
  try {
    const response = await apiClient.get(`/web/movie/${id}`);
    return response;
  } catch (error) {
    throw new Error();
  }
};

const seriesMovieDetail = async (id) => {
  try {
    const response = await apiClient.get(`/web/movie/${id}/episodes`);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

const makeMovieComment = async ({ _id, rating, comment }) => {
  try {
    const response = await apiClient.post(`/movie/${_id}/comment`, {
      rating,
      comment,
    });
    return response;
  } catch (error) {
    throw new Error();
  }
};

const getWishLists = async () => {
  try {
    const response = await apiClient.get("/customer/wishlist");
    return response.data;
  } catch (error) {
    throw new Error();
  }
};

const addToWishList = async ({ movieId }) => {
  try {
    const response = await apiClient.post(`/customer/wishlist`, { movieId });

    const obj = response.data.message;
    toast.success(obj);
    return response;
  } catch (error) {
    //console.log(error)
    const obj = error.response.data.error;
    //console.log(obj);
    toast.error(obj);
    return error;
  }
};

const removeFromWishList = async ({ movieId }) => {
  const response = await apiClient.delete(`/customer/wishlist/${movieId}`);
  try {
    const obj = response.data.message;
    toast.success(obj);
    return response;
  } catch (error) {
    const obj = error.response.data.message;
    toast.error(obj);
    throw new Error();
  }
};

const searchMovie = async ({ title }) => {
  try {
    const response = await apiClient.post(`/movie/search`, { title });

    return response;
  } catch (error) {
    throw new Error();
  }
};


const infiniteMovies = async ({ pageParam = 0 }) => {
  try {
    const response = await apiClient.get(`/web/single-movies-pagination?PageSize=12&PageNumber=${pageParam}`);

    return response.data;
  } catch (error) {
    throw new Error();
  }
};


const MovieService = {
  singleMovies,
  seriesMovies,
  seriesMoviesByid,
  latestMovies,
  latestShows,
  recommendedMovies,
  slideMovies,
  MovieDetail,
  seriesMovieDetail,
  popularMovies,
  makeMovieComment,
  addToWishList,
  getWishLists,
  removeFromWishList,
  searchMovie,
  infiniteMovies
};

export default MovieService;
