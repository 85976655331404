import React from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useParams } from "react-router";
import moment from "moment";
import MoviesComponent from "../components/moviesComponent/MoviesComponent";
import GenreService from "../services/GenreService";
import MovieService from "../services/MovieService";
import Loading from "../components/shared/Loading";

const MovieByGenere = () => {
  const queryClient = useQueryClient()

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } = useMutation(
    "addToWhishList",
    MovieService.addToWishList,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail")
      }
    })

  const onWhishListFormSubmit = (defaultValues) => {
    console.log(defaultValues)
    wishListMutate(defaultValues)
  }

  const genreId = useParams()
  console.log(genreId)

  const { data: movieData, isLoading: movieLoading, isFetching: movieFetching, isError: movieError } = useQuery(['movieByGenre', genreId.genreId], () => GenreService.movieByGenre(genreId.genreId));

  if (movieLoading)
    return (
  <Loading/>
    )

  const icon = "fas fa-plus text-white"
  console.log(movieData)
  const newMovieData = movieData.data.movies
  console.log(movieData.data.title)

  return (
    <div style={{ backgroundColor: 'black' }}>
      <section className="trending">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="block-title">{movieData.data.title}</h2>
              <div className="row">
                {
                  movieData &&
                  newMovieData.map(movie => {
                    return (
                      <>
                        <MoviesComponent key={movie._id} id={movie._id} title={movie.title} image={movie.poster} genre={movie.genre} postedDate={moment.utc(movie.createdAt).format('YYYY')} onWhishListFormSubmit={onWhishListFormSubmit} icon={icon} isMutateLoading={wishListMutateLoading} />
                      </>
                    )
                  })
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};


export default MovieByGenere