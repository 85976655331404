import axios from "axios";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}`,
    // baseURL: " http://localhost:3004",
    headers: {
        "Content-type": "application/json",
    },
});

const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}`,
    // baseURL: " http://localhost:3004",
    headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("token"),
    },
});

const verifyUser = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}`,
    // baseURL: " http://localhost:3004",
    headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + localStorage.getItem("verifyAccountToken"),
    },
});

const login = async ({ email, password }) => {
    try {
        const response = await apiClient.post('/customer/signin', { email, password });
        console.log(response)
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("isActive", response.data.active);
        toast.success("Login Successfully", {
            // theme: "colored",
        });
        return response
    }
    catch (error) {
        toast.error("error.message", {
            // theme: "colored",
        });
        throw new Error();
    }
}


const register = async ({ email, address, firstName, lastName, phone, password }) => {
    // email, address, firstName, lastName, phone, password
    try {
        const response = await apiClient.post('/customer/signup', { email, address, firstName, lastName, phone, password });
        localStorage.clear();
        localStorage.setItem("verifyAccountToken", response.data.accessToken);
        localStorage.setItem("token", response.data.accessToken);
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("isVerified", null);
        console.log(response)
        toast.success("Registered Successfully, Please Choose Your Payment Option", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        console.log(error)
        toast.error(error.response.data.error)
        throw new Error();
    }
}

const verifyAccount = async ({ conformation }) => {
    try {
        const response = await verifyUser.post('/customer/confirm', { conformation });
        console.log(response)
        const newResponse = response.data
        console.log(newResponse)
        localStorage.setItem("isVerified", true);
        if(newResponse === 'Account Verified'){
            toast.success("Account Verified", {
                // theme: "colored",
            });
        }
        // console.log(newResponse)
        return newResponse;
    }
    catch (error) {
        // const obj = error.response.data.message;
        console.log(error)
        toast.error("Invalid Confirmation Code", {
            // theme: "colored",
        });
        // 717vm91h9nj
        throw new Error();
    }
}

const verifyAccountByOtp = async ({ email }) => {
    try {
        const response = await verifyUser.post('/customer/otp-verify', { email });
        console.log(response)
        const newResponse = response.data
        // console.log(newResponse)
        return newResponse;
    }
    catch (error) {
        // const obj = error.response.data.message;
        console.log(error)
        toast.error("Invalid Confirmation Code", {
            // theme: "colored",
        });
        // 717vm91h9nj
        throw new Error();
    }
}

const chooseAccount = async () => {
    try {
        const response = await instance.get('/customer/profiles');
        console.log(response)
        return response.data;
    }
    catch (error) {
        throw new Error();
    }
}

const getProfile = async () => {
    try {
        const response = await instance.get('/customer/profile',);
        console.log(response);
        return response;
    }
    catch (error) {
        throw new Error();;
    }
}

const updateProfile = async ({ firstName, lastName, phone }) => {
    try {
        const response = await instance.patch('/customer/profile', { firstName, lastName, phone });
        console.log(response);
        toast.success("Profile Updated", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();;
    }
}

const updatePassword = async ({ oldPassword, newPassword }) => {
    try {
        const response = await instance.patch('/customer/password', { oldPassword, newPassword });
        console.log(response);
        toast.success("Password Changed", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.error;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();;
    }
}

const addUser = async ({ title, category }) => {
    try {
        const response = await instance.post('/customer/profiles', { title, category });
        console.log(response);
        toast.success("User Added", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();;
    }
}

const removeUser = async ( _id ) => {
    try {
        const response = await instance.delete(`/customer/profiles/${_id}`);
        console.log(response);
        toast.error("User Removed", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();;
    }
}

const forgetPassword = async ({ email }) => {
    try {
        const response = await apiClient.post('/customer/reset', { email });
        console.log(response);
        toast.success("Verification Link Sent To Your Email", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();;
    }
}

const resetPassword = async ({ newPassword }) => {
    try {
        const response = await apiClient.post('/customer/reset-password', { newPassword });
        console.log(response);
        toast.success("Verification Link Sent To Your Email", {
            // theme: "colored",
        });
        return response;
    }
    catch (error) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage, {
            // theme: "colored",
        });
        throw new Error();
    }
}

const AuthService = {
    login,
    register,
    chooseAccount,
    verifyAccount,
    verifyAccountByOtp,
    getProfile,
    updateProfile,
    updatePassword,
    addUser,
    removeUser,
    forgetPassword,
    resetPassword
}

export default AuthService;
