import React, { useEffect, createContext, useReducer, useContext } from "react";
import "./App.css";
import { Home } from "./pages/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { QueryClientProvider, QueryClient, useQuery } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import ScrollToTop from "./hooks/ScrolleToTop";
import { Navbar } from "./components/shared/Navbar";
import { Footer } from "./components/shared/Footer";
import { About } from "./components/shared/About";
import { PageNotFound } from "./components/shared/PageNotFound";
import { Movies } from "./pages/Movies";
import { Show } from "./pages/Show";
import { Contact } from "./components/shared/Contact";
import { Pricing } from "./components/shared/Pricing";
import { Setting } from "./components/shared/Setting";
import { Account } from "./components/shared/Account";
import { Wishlist } from "./components/shared/Wishlist";
import { ViewMovie } from "./pages/ViewMovie";
import Welcome from "./pages/Welcome";
import MovieDetail from "./pages/MovieDetail";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import Register from "./pages/auth/Register";
import ChooseAccount from "./pages/auth/ChooseAccount";
import MovieByGenere from "./pages/MovieByGenere";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Verification from "./pages/auth/Verification";
import AddUser from "./components/shared/AddUser";
import Search from "./components/shared/Search";
import ShowsList from "./pages/ShowsList";
import ResetPassword from "./pages/auth/ResetPassword";
import SendVerification from "./pages/auth/SendVerification";
import VerificationWithPhone from "./pages/auth/VerificationWithPhone";
import SocialMediaIcons from "./components/shared/SocialMediaIcons";
import ChooseVerificationType from "./pages/auth/ChooseVerificationType";
import Landing from "./pages/Landing";
import Loading from "./components/shared/Loading";
import PrivacyPolicy from "./components/shared/PrivacyPolicy";
import InfiniteScrollMovies from "./pages/InfiniteScrollMovies";

const Routing = () => {
  const { state, dispatch } = useContext(UserContext);
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  // const navigate = useNavigate();
  // const verifyUser = JSON.parse(user)
  const verifyUser = JSON.parse(localStorage.getItem("isVerified"));
  // console.log(verifyUser)
  // console.log(token)

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      dispatch({ type: "USER", payload: user });
      return null;
      // console.log(user);
    } else {
      // navigate("/");
    }
  }, []);

  if (user === []) {
    localStorage.clear();
    dispatch({ type: "CLEAR" });
    // navigate("/signin");
  }

  if ((token && verifyUser === true) || verifyUser === false) {
    return (
      <div>
        <Router>
          <ScrollToTop />
          <div id="loader-wrapper">
            <div id="loader"></div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
          </div>
          <div className="main" id="main">
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/choose-account" element={<ChooseAccount />} />
              <Route path="/home" element={<Home />} />
              <Route path="/movies" element={<Movies />} />
              <Route
                path="/movies-infinite"
                element={<InfiniteScrollMovies />}
              />
              <Route path="/movie/:movieId" element={<MovieDetail />} />
              <Route
                path="/movie-by-genre/:genreId"
                element={<MovieByGenere />}
              />
              <Route path="/series" element={<Show />} />
              <Route path="/series/:movieId" element={<ShowsList />} />
              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/about" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/setting" element={<Account />} />
              <Route path="/search" element={<Search />} />
              <Route path="/add-new-user" element={<AddUser />} />
              <Route path="/password-reset" element={<Setting />} />
              <Route path="/watch-list" element={<Wishlist />} />
              <Route path="/play" element={<ViewMovie />} />
              <Route path={"/loading"} element={<Loading />} />
              <Route path="*" element={<Home />} />
            </Routes>

            <SocialMediaIcons />
            <Footer />
          </div>
          <div>{/* <Welcome /> */}</div>
        </Router>
      </div>
    );
  } else {
    return (
      <>
        <Router>
          <ScrollToTop />
          <div id="loader-wrapper">
            <div id="loader"></div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
          </div>
          <>
            <Routes>
              <Route path="/" element={<Landing />} />
              {/* <Route path="/choose-account" element={<ChooseAccount />} /> */}
              <Route path="/signin" element={<Login />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/signup" element={<Register />} />
              <Route path="/verify-account" element={<Verification />} />
              <Route
                path="/verify-account-with-phone"
                element={<VerificationWithPhone />}
              />
              <Route path="/verify-with" element={<ChooseVerificationType />} />
              <Route path="/forget-password" element={<ForgetPassword />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/send-verification" element={<SendVerification />} />
              {/* <Route path="/about" element={<About />} /> */}
              <Route path="/about" element={<PrivacyPolicy />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </>

          <SocialMediaIcons />
          <Footer />
        </Router>
      </>
    );
  }
};

const initialState = null;

const queryClient = new QueryClient();
export const UserContext = createContext();
const reducer = (state, action) => {
  if (action.type == "USER") {
    return action.payload;
  }
  if (action.type == "CLEAR") {
    return null;
  }
  return state;
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <UserContext.Provider value={{ state, dispatch }}>
        <QueryClientProvider client={queryClient}>
          <>
            <div>
              <ToastContainer />
            </div>
            <Routing />
          </>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </UserContext.Provider>
    </div>
  );
}

export default App;
