import React from 'react'
import { Link } from 'react-router-dom'
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";

const MoviesComponent = ({ id, title, image, postedDate, genre, onWhishListFormSubmit, icon, isMutateLoading }) => {
  const [ movieId, setMovieId ] = React.useState('')
  const validationSchema = Yup.object().shape({
    movieId: Yup.string()
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm({
    //   defaultValues,
    resolver: yupResolver(validationSchema)
  });

  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE

  console.log(movieId)
  return (

    <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
      <div className="video-block">
        <div className="video-thumb position-relative thumb-overlay">
          <Link to={`/movie/${id}`}>
            <img
              // alt=""
              className="img-fluid"
              src={`${imageURL}/${image}`}
              alt="https://api.etnet.et/images/2022-05-07T08-45-16.090Z_FB_IMG_1649966991595.jpg"
            />
          </Link>
          <div className="box-content">
            <ul className="icon">
              <li>
                <Link to={`/movie/${id}`}>
                  <i className="fas fa-play"></i>
                </Link>
              </li>
              <li>
                <form onSubmit={handleSubmit(onWhishListFormSubmit)}>
                  <input type="hidden" {...setValue('movieId', id, { shouldTouch: true })} />
                  <button type='submit' onClick={() => {
                    // setMovieId("")
                    setMovieId(id)
                  }}>
                    <i className={icon}></i>
                  </button>
                </form>
              </li>
              {/* <li>
                  <a href={`movie/${id}`}>
                    <i className="fas fa-info"></i>
                  </a>
                </li> */}
            </ul>
          </div>
        </div>

        <div className="video-content">
          <h2 className="video-title">
            <Link to={`/movie/${id}`}>{title}</Link>
          </h2>
          <div className="video-info d-flex align-items-center">
            <span className="video-year">{postedDate}</span>{" "}
            {/* <span className="video-age">+18</span>{" "} */}
            {/* <span className="video-type">{genre}</span> */}
          </div>
          {/* <p>{isMutateLoading ? "Loading..." : ""}</p> */}
          <p>{movieId === id && isMutateLoading ? "Loading..." : ""}</p>
        </div>
      </div>
    </div>

  )
}

export default MoviesComponent