import React from "react";
import Skeleton from "react-loading-skeleton";
import { useQuery } from "react-query";
import LatestMoviesComponent from "../components/moviesComponent/LatestMoviesComponent";
import MovieService from "../services/MovieService";
import LatestShowsComponent from '../components/moviesComponent/LatestShowsComponent';
import Loading from "../components/shared/Loading";

export const Show = () => {

  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE

  const { data: seriesMovies, isLoading: seriesMoviesLoading, isError: seriesMoviesError } = useQuery('seriesMovies', MovieService.seriesMovies);
  const { data: allMovies, isLoading: allMoviesLoading, isError: allMoviesError } = useQuery('allMovies', MovieService.singleMovies);


  if (seriesMoviesLoading)
    return (
        <Loading/>
    )
  if (seriesMoviesError)
    return (
        <div className="d-flex justify-content-center align-items-center">
        Something Went Wrong
      </div>
    )
  const newSeriesMovies = seriesMovies.data


  return (
    <div style={{ backgroundColor: 'black' }}>
     <section className="pupular">
        <div className="container-fluid">
          <div className="row">
          <div className="col-lg-12">
          <h2 className="block-title">Series</h2>
          </div>
          {
            newSeriesMovies.length > 0
            ?
            newSeriesMovies.map((movies) => {
                return (
                  <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                    <LatestShowsComponent
                      key={movies.id}
                      id={movies._id}
                      title={movies.title}
                      image={`${imageURL}/${movies.poster}`}
                      category={movies.category}
                      postedDate={movies.createdAt}
                      views={movies.views}
                    // isLoading={moviesLoading}
                    />
                  </div>
                )
              })
              :
            (
              <h3>No Data Found</h3>
            )
            }
          </div>
        </div>
      </section>
    </div>
  );
};
