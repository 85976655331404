import React, { useContext, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { UserContext } from "../../App";
import GenreService from "../../services/GenreService";
import MovieService from "../../services/MovieService";

export const Navbar = () => {
  const queryClient = useQueryClient();
  const {
    data: genreData,
    isLoading: genreLoading,
    isError: genreError,
  } = useQuery("genreList", GenreService.genreList);
  const { mutateAsync: searchMovieMutate, isLoading: searchMovieLoading } =
    useMutation("searchMovie", MovieService.searchMovie, {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail");
      },
    });

  const { state, dispatch } = useContext(UserContext);
  const logState = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    search: Yup.string(),
    // .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    //   defaultValues,
    resolver: yupResolver(validationSchema),
  });

  // console.log(genreLoading ? "Loading" : genreData)
  let newGenreData = [];
  newGenreData = genreData;

  // if (genreLoading)
  //   return (
  //     <div>
  //       Loading...
  //     </div>
  //   )

  const onFormSubmit = (defaultValues) => {
    console.log(defaultValues);
    searchMovieMutate(defaultValues);
  };
  return (
    <div>
      <header className="main-nav clearfix is-sticky">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-lg-9 pl-0">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img
                    alt=""
                    className="img-fluid"
                    src="../assets/logo.3113682a.png"
                    style={{
                      height: "70px",
                      width: "100%",
                      marginTop: "-30px",
                      marginBottom: "-30px",
                      zIndex: 1,
                    }}
                  />
                </Link>

                <div className="site-nav-inner">
                  <button
                    className="navbar-toggler"
                    data-target="#navbarSupportedContent"
                    // id="dropdownNavLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="fa fa-bars"></span>
                  </button>
                  <div className="dropdown w-100">
                    <ul
                      className="dropdown-menu w-100 text-small"
                      aria-labelledby="dropdownNavLink"
                    >
                      <li className="nav-item" style={{ marginLeft: "-10px" }}>
                        <Link className="nav-link" to={"/"}>
                          Home
                        </Link>
                      </li>
                      {/* <li className="nav-item" style={{ marginLeft: "-10px" }}>
                        <Link className="nav-link " to={"/movies"}>
                          Movies
                        </Link>
                      </li> */}
                      <li className="nav-item" style={{ marginLeft: "-10px" }}>
                        <Link className="nav-link " to={"/movies-infinite"}>
                          Movies
                        </Link>
                      </li>
                      <li className="nav-item" style={{ marginLeft: "-10px" }}>
                        <Link className="nav-link " to={"/series"}>
                          Series
                        </Link>
                      </li>
                      <li className="nav-item" style={{ marginLeft: "-10px" }}>
                        <Link className="nav-link " to={"/watch-list"}>
                          Wish List
                        </Link>
                      </li>
                      <li
                        className="nav-item dropdown"
                        style={{ marginLeft: "-10px" }}
                      >
                        <Link
                          className="nav-link menu-dropdown"
                          data-toggle="dropdown"
                          to="#"
                        >
                          Genres <i className="fa fa-angle-down"></i>
                        </Link>
                        <ul className="dropdown-menu ml-5 fade-up" role="menu">
                          {genreLoading
                            ? "Loading..."
                            : newGenreData &&
                              newGenreData.data.map((genres) => {
                                return (
                                  <div key={genres._id}>
                                    <li>
                                      <Link
                                        key={genres._id}
                                        className="dropdown-item"
                                        to={`/movie-by-genre/${genres._id}`}
                                      >
                                        {genres.title}
                                      </Link>
                                    </li>
                                  </div>
                                );
                              })}
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div
                    // className="dropdown"
                    className="dropdown navbar-collapse  navbar-responsive-collapse collapse"
                    id="navbarSupportedContent"
                  >
                    <ul className="nav navbar-nav">
                      <li className="nav-item">
                        <Link className="nav-link active" to={"/"}>
                          Home
                        </Link>
                      </li>
                      {/* <li className="nav-item">
                        <Link className="nav-link " to={"/movies"}>
                          Movies
                        </Link>
                      </li> */}
                      <li className="nav-item">
                        <Link className="nav-link " to={"/movies-infinite"}>
                          Movies
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to={"/series"}>
                          Series
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link " to={"/watch-list"}>
                          Wish List
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link
                          className="nav-link menu-dropdown"
                          data-toggle="dropdown"
                          to="#"
                        >
                          Genres <i className="fa fa-angle-down"></i>
                        </Link>
                        <ul className="dropdown-menu fade-up" role="menu">
                          {genreLoading
                            ? "Loading..."
                            : newGenreData &&
                              newGenreData.data.map((genres) => {
                                return (
                                  <div key={genres._id}>
                                    <li>
                                      <Link
                                        key={genres._id}
                                        className="dropdown-item"
                                        to={`/movie-by-genre/${genres._id}`}
                                      >
                                        {genres.title}
                                      </Link>
                                    </li>
                                  </div>
                                );
                              })}
                        </ul>
                      </li>
                      {/* <li className="nav-item dropdown">
                        <a
                          className="nav-link menu-dropdown"
                          data-toggle="dropdown"
                          to="#"
                        >
                          Pages <i className="fa fa-angle-down"></i>
                        </a>
                        <ul className="dropdown-menu fade-up" role="menu">
                          <li>
                            <a className="dropdown-item" to="about.html">
                              About Us
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" to="contacts.html">
                              Contact Us
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" to="404.html">
                              404
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" to="login.html">
                              Login
                            </a>
                          </li>
                          <li>
                            <a className="dropdown-item" to="sign-up.html">
                              Signup
                            </a>
                          </li>
                        </ul>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>

            <div className="col-lg-3 text-right right-menu-wrap">
              <ul className="nav d-flex align-items-center list-inline m-0 float-right">
                {/* <li className="nav-item">
                  <div className="nav-search">
                    <a className="nav-link modal-popup" to="#search-popup">
                      <i className="icofont-ui-search"></i>
                    </a>
                  </div>
                </li> */}

                <li className="nav-item">
                  <div className="nav-account ml-2">
                    <div className="dropdown">
                      <Link to="/search">
                        <i className="icofont-ui-search"></i>
                      </Link>
                      {/* <div
                        aria-expanded="false"
                        aria-haspopup="true"
                        data-toggle="dropdown"
                        id="dropdown-account"
                        role="button"
                      >
                        <Link to='/search'>
                          <i className="icofont-ui-search"></i>
                        </Link>
                      </div> */}
                      {/* <ul className="dropdown-menu dropdown-menu-right fade-up">
                        <li>
                        <form onSubmit={handleSubmit(onFormSubmit)}>
                          <div className="row col-12">
                            <div className="col-9">
                            <input className="form-control" {...register('title')} placeholder="Search Here" />
                            </div>
                            <div className="col-3">
                              <button type="submit" className="btn btn-sm btn-danger">Search</button>
                              </div>
                          </div>
                          </form>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <div className="nav-account ml-2">
                    <div className="dropdown">
                      <div
                        aria-expanded="false"
                        aria-haspopup="true"
                        data-toggle="dropdown"
                        id="dropdown-account"
                        role="button"
                      >
                        <img
                          alt=""
                          className="img-fluid user-icon rounded-circle"
                          src="../../assets/images/avatar/user.jpg"
                        />
                      </div>
                      <ul className="dropdown-menu dropdown-menu-right fade-up">
                        <li>
                          <Link className="dropdown-item" to="/setting">
                            Account Settings
                          </Link>
                        </li>
                        <li>
                          <Link className="dropdown-item" to="/watch-list">
                            whish list
                          </Link>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              localStorage.clear();
                              dispatch({ type: "CLEAR" });
                              navigate("/");
                            }}
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

// export default Navbar;
