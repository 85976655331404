import axios from "axios";
import { toast } from 'react-toastify';

const token = localStorage.getItem("token")

const apiClient = axios.create({
    baseURL: `${process.env.REACT_APP_API_SERVER}`,
    // baseURL: " http://localhost:3004",
    headers: {
        "Content-type": "application/json",
        Authorization: "Bearer " + token,
    },
});


const genreList = async () => {
    try {
        const response = await apiClient.get('web/genre-list')
        return response;
    }
    catch (error) {
        console.log(error);
         throw new Error();
    }
}

const movieByGenre = async(id) => {
    try{
        const response = await apiClient.get(`web/genre-list/${id}`)
        return response;
    }
    catch (error) {
        console.log(error);
         throw new Error();
    }
}

const GenreService =  {
    genreList,
    movieByGenre
}

export default GenreService