import React from "react";

export const Setting = () => {
  return (
    <div style={{ backgroundColor: 'black' }}>
      <div class="sub-header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav aria-label="breadcrumb" class="text-center breadcrumb-nav">
                <h2 class="Page-title">Reset Password</h2>
                <ol class="breadcrumb">
                  <li>
                    <i class="fa fa-home"></i>
                    <a href="#">Home</a>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a href="#">Reset Password</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <section class="reset-password">
          <div class="container">
            <div class="row text-center intro">
              <div class="col-12">
                <h2>Forgot Password ?</h2>
                <p class="text-max-800">
                  You Can Reset Password Using This Form.
                </p>
              </div>
            </div>
            <div class="row h-100">
              <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                <div class="d-table-cell align-middle">
                  <form id="reset-form" action="#" method="post">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        class="form-control form-control-lg"
                        type="email"
                        name="email"
                        placeholder="Enter your email"
                      />
                    </div>
                    <div class="text-center mt-3">
                      <a href="#" class="btn hvr-sweep-to-right">
                        Reset password
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
