import React, { useState, useContext } from "react";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../services/AuthService";
import { UserContext } from "../../App";
import axios from "axios";

const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_SERVER}`,
  // baseURL: " http://localhost:3004",
  headers: {
    "Content-type": "application/json",
  },
});

const Login = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state, dispatch } = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);

  const passwordShow = () => {
    showPassword === false ? setShowPassword(true) : setShowPassword(false);
  };
  //console.log(showPassword)
  const login = async ({ email, password }) => {
    setLoading(true);
    try {
      const response = await apiClient.post("/customer/signin", {
        email,
        password,
      });
      localStorage.clear("token");
      localStorage.clear("user");
      //console.log(response)
      localStorage.setItem("token", response.data.accessToken);
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("verifyAccountToken", response.data.accessToken);
      localStorage.setItem("isVerified", response.data.user.verified);
      dispatch({ type: "USER", payload: response.user });

      if (
        response.data.user.verified === true ||
        response.data.user.verified === false
      ) {
        toast.success("Login Success", {
          // theme: "colored",
        });

        navigate("/home");
        window.location.reload();
      }

      return response;
      setLoading(false);
    } catch (error) {
      //console.log(error)
      const obj = error.response.data.error;
      //console.log(obj);
      toast.error(obj, {
        // theme: "colored",
      });
      return error;
    }
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    mutateAsync: loginMutate,
    isLoading: loginLoading,
    isError: loginError,
  } = useMutation(login, {
    onSuccess: () => {
      queryClient.invalidateQueries("getAllMovies");
    },
  });
  // const onSubmit = data => //console.log(data);
  const onSubmit = (data) => {
    //console.log(data)
    loginMutate(data);
    // navigate('/choose-account')
  };

  return (
    <div
      className="container-fluid"
      style={{ backgroundColor: "black", marginTop: "-1%" }}
    >
      <div>
        <ToastContainer />
      </div>
      <div className="row d-flex justify-content-between mt-3 p-3 p-lg-0">
        <Link to="/">
          <img
            src="../../assets/logo.3113682a.png"
            alt=""
            style={{ widht: "100px", height: "100px" }}
            className="image-fluid"
          />
        </Link>
        <Link to="/signup">
          <button className="btn btn-danger mt-3">Register</button>
        </Link>
      </div>
      <div className="main-content">
        <section className="login">
          <div className="container">
            <div className="row text-center intro">
              <div className="col-12">
                <h2>Welcome Back !</h2>
                <hr />
                {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mt-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="error-container"></div>
                  <div className="form-group">
                    <label className="control-label col-xs-4">Username</label>
                    <input
                      type="email"
                      {...register("email")}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group">
                    <label className="control-label col-xs-4">Password</label>
                    <div className="input-group flex-nowrap">
                      <input
                        type={showPassword === false ? "password" : "text"}
                        {...register("password")}
                        className="form-control"
                      />
                      <span
                        className="input-group-text"
                        id="addon-wrapping"
                        onClick={passwordShow}
                      >
                        {showPassword === false ? (
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        ) : (
                          <i className="fa fa-eye-slash" aria-hidden="true"></i>
                        )}
                      </span>
                    </div>
                  </div>

                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                    >
                      {loginLoading ? "Loading..." : "Login"}
                    </button>
                  </div>
                </form>
                <div className="row d-flex align-items-center mb-4">
                  <div className="col-12">
                    <h5 className="text-center">
                      Don't have an account?
                      {/* <a href="/signup">Sign up here!</a> */}
                      &nbsp;{" "}
                      <Link
                        to="/signup"
                        style={{ textDecoration: "none", color: "red" }}
                      >
                        Signup Here
                      </Link>
                    </h5>
                  </div>
                  <div className="col-12">
                    <h5 className="text-center text-danger">
                      &nbsp;{" "}
                      <Link
                        to="/forget-password"
                        style={{ textDecoration: "none" }}
                      >
                        Forget Password{" "}
                      </Link>
                    </h5>
                  </div>

                  {/* <a href="reset-password.html">Lost your Password?</a> */}
                  <div className="col-md-6">
                    {/* <a href="watch-movie.html" className="btn facebook-color d-block hvr-sweep-to-right mb-3" tabIndex="0"><i className="icofont-facebook mr-2" aria-hidden="true"></i>Facebook</a> */}
                  </div>

                  <div className="col-md-6">
                    {/* <a href="watch-movie.html" className="btn twitter-color d-block hvr-sweep-to-right mb-3" tabIndex="0"><i className="icofont-twitter mr-2" aria-hidden="true"></i>Twitter</a> */}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 d-none d-lg-block"
                style={{ marginTop: "-10%" }}
              >
                <img
                  className="img-fluid signin-image"
                  src="/assets/logo.3113682a.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Login;
