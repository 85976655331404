import React from "react";
import { Link } from "react-router-dom";

export const About = () => {
  return (
    <div style={{ backgroundImage: `url('/assets/images/landing_image.jpg')` }}>
      <div className="row d-flex justify-content-between p-5 pt-0">
        <Link to="/">
          <img
            src="../../assets/logo.3113682a.png"
            alt=""
            style={{ widht: "100px", height: "100px" }}
            className="image-fluid"
          />
        </Link>
        <Link to="/signup">
          <button className="btn btn-danger mt-3">Register</button>
        </Link>
      </div>
      <div class="">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav aria-label="breadcrumb" class="text-center breadcrumb-nav">
                <h2 class="Page-title mt-5">About Us</h2>
                <ol class="breadcrumb">
                  <li>
                    <i class="fa fa-home"></i>
                    <a href="/">Home</a>
                    <i class="fa fa-angle-right"></i>
                  </li>
                  <li>
                    <a href="#">About Us</a>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        {/* <section class="statistics">
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <h2 class="block-title">Our Statistics</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-6 col-xl mb-5 mb-xl-0">
                <div class="icon-box text-center">
                  <div class="icon">
                    <i class="fas fa-video"></i>
                  </div>
                  <div
                    class="number count1"
                    data-from="10"
                    data-to="9300"
                    data-time="1000"
                  >
                    0
                  </div>
                  <p>Movies</p>
                </div>
              </div>
              <div class="col-6 col-xl mb-5 mb-xl-0">
                <div class="icon-box text-center">
                  <div class="icon">
                    <i class="fas fa-eye"></i>
                  </div>
                  <div
                    class="number count2"
                    data-from="10"
                    data-to="7400"
                    data-time="1000"
                  >
                    0
                  </div>
                  <p>Shows</p>
                </div>
              </div>
              <div class="col-6 col-xl mb-5 mb-xl-0">
                <div class="icon-box text-center">
                  <div class="icon">
                    <i class="fas fa-users"></i>
                  </div>
                  <div
                    class="number count3"
                    data-from="10"
                    data-to="1500"
                    data-time="1000"
                  >
                    0
                  </div>
                  <p>Members</p>
                </div>
              </div>
              <div class="col-6 col-xl mb-5 mb-xl-0">
                <div class="icon-box text-center">
                  <div class="icon">
                    <i class="fas fa-trophy"></i>
                  </div>
                  <div
                    class="number count4"
                    data-from="10"
                    data-to="860"
                    data-time="1000"
                  >
                    0
                  </div>
                  <p>Awards</p>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <div class="gap-50"></div>
        <section class="subscribe">
          <div class="container">
            <div
              class="row bg-black"
              style={{ backgroundColor: "black", padding: 5 }}
            >
              <div class="col-md-6">
                <img
                  class="img-fluid mb-4"
                  src="/assets/images/all_device.jpg"
                  alt=""
                />
              </div>
              <div class="col-md-6">
                <h2 class="mb-4 mt-4">Etnet</h2>
                <p class="mb-4">
                  EtNet is a streaming service that offers a wide variety of
                  Ethiopian films, series and more. For a flat monthly
                  subscription fee, you can stream an unlimited number of
                  movies, anytime, without ads.
                </p>
                <p class="mb-4">
                  Watch EtNet on your smartphone, tablet, Smart TV, laptop, or
                  streaming device, all for one fixed monthly fee. Plans range
                  from 40 birr $6 a month. No extra costs.
                </p>
                <p>
                  In Ethiopia, you can pay using all currently available mobile
                  wallets (CBE Birr, Amole, Hello Cash and others) We also
                  accept Paypal payments and all major credit cards.
                </p>
                {/* <a class="btn hvr-sweep-to-right">Subscribe Now</a> */}
                <p class="mb-4">
                  EtNet is flexible. There are no pesky contracts and no
                  commitments. You can easily cancel your account online in two
                  clicks. There are no cancellation fees – start or stop your
                  account anytime.
                </p>
                <p class="mb-4">
                  EtNet has an extensive library of feature films,
                  documentaries, TV shows. EtNet originals, and more. Watch as
                  much as you want, anytime you want.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
