import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthService from "../../services/AuthService";

const ForgetPassword = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
  });

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const {
    mutateAsync: forgetPasswordMutate,
    isLoading: forgetPasswordLoading,
    // isError: forgetPasswordError,
    status,
  } = useMutation(AuthService.forgetPassword, {
    onSuccess: (response) => {
      queryClient.invalidateQueries("getAllMovies");
      console.log(response);
      navigate("/signin");
      // window.location.reload();
    },
    onError: (error) => {
      toast.error("Something went wrong");
      console.log(error);
    },
  });

  const onSubmit = (data) => {
    console.log(data);
    forgetPasswordMutate(data);
  };

  return (
    <div>
      <div style={{ backgroundColor: "black" }}>
        <div>
          <ToastContainer />
        </div>
        <div className="main-content">
          <section className="login">
            <div className="container">
              <div className="row text-center intro">
                <div className="col-12">
                  <h2>Forget Password</h2>
                  <hr />
                  {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mt-4">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="error-container"></div>
                    <div className="form-group">
                      <label className="control-label col-xs-4">Email</label>
                      <input
                        type="email"
                        {...register("email")}
                        className="form-control"
                      />
                    </div>

                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                      >
                        {forgetPasswordLoading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </form>
                  <div className="row d-flex align-items-center mb-4">
                    <div className="col-12">
                      <h5 className="text-center">
                        Do have an account? &nbsp;{" "}
                        <Link
                          to="/signin"
                          style={{ textDecoration: "none", color: "red" }}
                        >
                          Login Here
                        </Link>
                      </h5>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-6 d-none d-lg-block"
                  style={{ marginTop: "-10%" }}
                >
                  <img
                    className="img-fluid signin-image"
                    src="/assets/logo.3113682a.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ForgetPassword;
