import React from "react";
import { Link } from "react-router-dom";
import FrequentlyAskedQuestion from "../components/shared/FrequentlyAskedQuestion";
import Video from "../hooks/Video";

const Landing = () => {
  return (
    <>
      <div className="container-fluid landing-image w-100">
        <div className="row d-flex justify-content-between mt-3 p-3 p-lg-0">
          {/* <h4>Logo</h4> */}
          <img
            src="../../assets/logo.3113682a.png"
            alt=""
            style={{ widht: "100px", height: "100px" }}
            className="image-fluid"
          />
          <Link to="/signin">
            <button className="btn btn-danger mt-3">Signin</button>
          </Link>
        </div>
        <div className="row d-flex justify-content-center align-items-center mt-5 pt-5">
          <div className="col-12">
            <h1 className="mt-lg-5 text-center landing-h1">
              Enjoy our selection of Ethiopian movies, <br /> series and much
              more..
            </h1>
          </div>

          <div className="col-md-6 mt-3 d-flex justify-content-center">
            <Link to="/signup">
              {" "}
              <button className="btn btn-danger">Get Started</button>
            </Link>
          </div>
        </div>
      </div>
      <h4 className="text-white" />
      <div
        className="container-fluid bg-black d-flex justify-content-center"
        style={{ backgroundColor: "black" }}
      >
        <div className="row d-lg-flex justify-content-center d-none">
          <div className="col-md-6 mt-5 p-5 pb-sm-0">
            <h1 className="text-white mb-3 mb-sm-0">Watch everywhere. </h1>
            <p className="text-white">
              Stream unlimited Ethiopian movies and TV shows on your phone,
              tablet, laptop, and TV without paying more.
            </p>
          </div>
          <div
            className="col-md-6 mt-2 mt-sm-0 d-flex justify-content-center"
            style={{
              // backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/tv.png')`,
              backgroundImage: `url('../../assets/images/laptop.png')`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              minHeight: "500px",
            }}
          >
            <video
              className="our-story-card-video mr-lg-5 pr-lg-5 mb-5 pb-5"
              data-uia="our-story-card-video"
              autoplay=""
              playsinline=""
              muted
              loop
              style={{ width: "50%" }}
            >
              <source
                src="../../assets/video/landing_video.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className="row d-lg-none d-block justify-content-center">
          <div className="col-md-6 mt-5 p-5 pb-0 text-center">
            <div className="col-12">
              <h3 className="text-white mb-3 mb-sm-0">Watch everywhere. </h3>
              <p className="text-white">
                Stream unlimited Ethiopian movies and TV shows on your phone,
                tablet, laptop, and TV without paying more.
              </p>
            </div>

            <div
              className="col-12"
              style={{
                // backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/tv.png')`,
                backgroundImage: `url('../../assets/images/laptop.png')`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                minHeight: "250px",
              }}
            >
              <video
                className="our-story-card-video ml-5 pt-5"
                data-uia="our-story-card-video"
                autoplay=""
                playsinline=""
                muted
                loop
                style={{ width: "55%" }}
              >
                <source
                  src="../../assets/video/landing_video.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            {/* <img
              src="https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/tv.png"
              className="imge-fluid"
            /> */}
          </div>
        </div>
      </div>
      {/*  */}

      <h4 className="text-white" />
      <div
        className="container-fluid bg-black d-flex justify-content-center"
        style={{ backgroundColor: "black" }}
      >
        <div className="row d-lg-flex justify-content-center d-none">
          <div className="col-md-6 mt-2 mt-sm-0">
            <img
              // src="https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/mobile-0819.jpg"
              src="../../assets/images/mobile_download.jpg"
              className="imge-fluid"
            />
          </div>
          <div className="col-md-6 mt-5 p-5 pb-sm-0">
            <h1 className="text-white mb-3 mb-sm-0">
              Download your shows to watch offline.{" "}
            </h1>
            <p className="text-white">
              Save your favorites easily and always have something to watch.
            </p>
          </div>
        </div>
        <div className="row d-lg-none d-block justify-content-center">
          <div className="col-md-6 mt-5 p-5 pb-sm-0 text-center">
            <h3 className="text-white mb-3 mb-sm-0">
              Download your shows to watch offline.
            </h3>
            <p className="text-white">
              Save your favorites easily and always have something to watch.
            </p>
            {/* <img
              src="https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/mobile-0819.jpg"
              className="imge-fluid"
            /> */}
            <img
              src="../../assets/images/mobile_download.jpg"
              className="imge-fluid"
            />
          </div>
        </div>
      </div>

      {/*  */}
      <h4 className="text-white" />
      <div
        className="container-fluid bg-black d-flex"
        style={{ backgroundColor: "black" }}
      >
        <div className="row d-lg-flex mt-5 justify-content-between ml-5 d-none">
          <div className="col-md-6 mt-5">
            <h1 className="text-white ">
              Enjoy our selection of Ethiopian movies, series and much more.
            </h1>
          </div>
          <div
            className="col-md-6 mt-2 d-flex "
            style={{
              backgroundImage: `url('../../assets/images/mobile.png')`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              width: "100%",
              minHeight: "450px",
              // marginLeft: '5px'
            }}
          ></div>
        </div>
        <div className="row d-lg-none d-block justify-content-center">
          <div className="col-md-6 mt-5 text-center">
            <div className="col-12">
              <h3 className="text-white ">
                Enjoy our selection of Ethiopian movies, series and much more.
              </h3>
            </div>
            <div
              className="col-12 ml-4"
              style={{
                // backgroundImage: `url('https://assets.nflxext.com/ffe/siteui/acquisition/ourStory/fuji/desktop/tv.png')`,
                backgroundImage: `url('../../assets/images/mobile.png')`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                width: "100%",
                minHeight: "250px",
              }}
            ></div>
          </div>
        </div>
      </div>
      <h4 className="text-white" />
      <div
        className="container-fluid bg-black d-flex justify-content-center"
        style={{ backgroundColor: "black" }}
      >
        <div className="row d-lg-flex justify-content-center d-none">
          <div className="col-md-6 mt-2 mt-sm-0">
            <img
              src="../../assets/images/all_device.jpg"
              className="imge-fluid"
            />
          </div>
          <div className="col-md-6 mt-3 p-3 pb-sm-0 pt-5">
            <h1 className="text-white mb-3 mt-5">
              Subscribe once and stream as much content as you like.
            </h1>
          </div>
        </div>
        <div className="row d-lg-none d-block justify-content-center">
          <div className="col-md-6 mt-5 p-5 pb-sm-0 text-center">
            <h3 className="text-white mb-3 mb-sm-0">
              Subscribe once and stream as much content as you like.
            </h3>
            {/*<p className="text-white">*/}
            {/*  Watch on Smart TVs, Playstation, Xbox, Chromecast, Apple TV,*/}
            {/*  Blu-ray players, and more.*/}
            {/*</p>*/}
            {/* <img
              src="https://occ-0-1490-1489.1.nflxso.net/dnm/api/v6/19OhWN2dO19C9txTON9tvTFtefw/AAAABfpnX3dbgjZ-Je8Ax3xn0kXehZm_5L6-xe6YSTq_ucht9TI5jwDMqusWZKNYT8DfGudD0_wWVVTFLiN2_kaQJumz2iivUWbIbAtF.png?r=11f.png"
              className="imge-fluid"
            /> */}
            <img
              src="../../assets/images/all_device.jpg"
              className="imge-fluid"
            />
          </div>
        </div>
      </div>

      <h4 className="text-white" />
      <div
        className="container-fluid bg-black d-flex justify-content-center"
        style={{ backgroundColor: "black" }}
      >
        <div className="row d-lg-block d-none">
          <div className="col-md-12 mt-5 p-5">
            <h1 className="text-white text-center mb-3 mb-sm-0">
              Frequently Asked Questions
            </h1>
            {/* <p className="text-white">
              Watch on Smart TVs, Playstation, Xbox, Chromecast, Apple TV,
              Blu-ray players, and more.
            </p> */}
          </div>
        </div>

        <div className="row d-lg-none d-block justify-content-center">
          <div className="col-md-6 mt-5 text-start">
            <h3 className="text-white">Frequently Asked Questions</h3>
            {/* <p className="text-white">
              Watch on Smart TVs, Playstation, Xbox, Chromecast, Apple TV,
              Blu-ray players, and more.
            </p> */}
          </div>
        </div>
      </div>

      <FrequentlyAskedQuestion />
    </>
  );
};

export default Landing;
