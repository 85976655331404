import React from "react";

import YenePayCheckoutButton from "react-yenepay-express-checkout";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import PayWithYenePay from "./PayWithYenePay";
import PayWithPayPal from './PayWithPayPal';

export const Pricing = () => {
  return (
    <div
      className="container-fluid bg-black"
      style={{ backgroundColor: "dark" }}
    >
      <div className="row d-flex justify-content-between mt-3 p-3 p-lg-0">
        <Link to="/">
          <img
            src="../../assets/logo.3113682a.png"
            alt=""
            style={{ widht: "100px", height: "100px" }}
            className="image-fluid"
          />
        </Link>
        <Link to="/signin">
          <button className="btn btn-danger mt-3">Login</button>
        </Link>
      </div>
      <div class="sub-header">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-sm-12">
              <nav aria-label="breadcrumb" class="text-center breadcrumb-nav">
                <h2 class="Page-title">Pricing Plans</h2>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div class="main-content">
        <section class="pricing">
          <div class="container">
            <div class="row text-center intro">
              <div class="col-12">
                <h2>Choose Payment Option</h2>
                {/* <p class="text-max-800">
                  Choose the ideal plan for what you need. We work with
                  affordable prices for all types of pocket.
                </p> */}
              </div>
            </div>
            <ul class="nav nav-tabs">
              <li class="active">
              <button className="btn btn-danger mr-2" data-toggle="tab" href="#home" style={{ backgroundColor: '#017bfe'}}>
                YenePay
                </button>
              </li>
              <li>
              <button className="btn btn-secondary" data-toggle="tab" href="#menu1" style={{ backgroundColor: '#ffc33a'}}>
                Paypal
                </button>
              </li>
            </ul>
            <div class="row d-flex">
              <div class="tab-content">
                <div id="home" class="tab-pane fade in active">
                  <PayWithYenePay />
                </div>

                <div id="menu1" class="tab-pane fade">
                  <PayWithPayPal/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
