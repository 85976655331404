import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import firebase from "./firebase";
import { useMutation } from "react-query";
import AuthService from "../../services/AuthService";

const ChooseVerificationType = () => {
  const navigate = useNavigate();
  const [mobile, setMobile] = useState();
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState();
  const [verifyWithEmail, setVerifyWithEmail] = useState(false);
  const [verifyWithPhone, setVerifyWithPhone] = useState(false);
  const [sendOtpData, setSendOtpData] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("user"));
  const userPhone = userInfo.customer.phone;
  const userEmail = userInfo.customer.email;

  console.log(userInfo)

  if (verifyWithEmail === true) {
    navigate("/verify-account");
    window.location.reload();
  }

  //Verify user
  const { mutateAsync, isLoading } = useMutation(
    AuthService.verifyAccountByOtp,
    {
      onSuccess: (response) => {
        console.log(response);
      },
      onError: () => {},
    }
  );
  //Send OTP
  const sendOtp = (e) => {
    e.preventDefault();
    configureCaptcha();
    const appVerifier = window.recaptchaVerifier;
    firebase
      .auth()
      .signInWithPhoneNumber('+251988141916', appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setSendOtpData(true);
        toast.success('OTP has been sent');
        // console.log("OTP has been sent");
        // navigate("/verify-account-with-phone");
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        toast.error("SMS not sent, To Many Attempts try again letter");
        console.log("SMS not sent");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const code = otp;
    console.log(code);
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        // User signed in successfully.
        const user = result.user;
        console.log("result", result);
        console.log(JSON.stringify(user));

        // localStorage.setItem("isVerified", true);

        const data = {
          email: userEmail,
        };
        mutateAsync(data)
        .then(
          navigate("/pricing"),
          window.location.reload()
        )
    
        // ...
      })
      .catch((error) => {
        // User couldn't sign in (bad verification code?)
        // ...
        toast.error('Invalid Code');
      });
    // ...
  };

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          console.log(response);
          // navigate("/verify-account-with-phone");
        },
      }
    );
  };

  console.log(userEmail);

  return (
    <>
      <div style={{ backgroundColor: "black" }}>
        <div id="sign-in-button"></div>
        <div>
          <ToastContainer />
        </div>

        <div className="main-content">
          <section className="login">
            <div
              className={
                verifyWithPhone && sendOtpData === true
                  ? "container d-none"
                  : "container d-block"
              }
            >
              <div className="row text-center intro">
                <div className="col-12">
                  <h2>Select Verification Type</h2>
                  <hr />
                  {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
              </div>
              <div className="row d-flex justify-content-center">
                <div className="col-lg-6">
                  <div className="form-group">
                    <button
                      type="submit"
                      className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                      onClick={() => {
                        setVerifyWithEmail(true);
                        setVerifyWithPhone(false);
                        console.log("Email Verification");
                      }}
                    >
                      Email
                    </button>
                  </div>
                </div>
                <div className="col-lg-6">
                  <form onSubmit={sendOtp}>
                    <div className="form-group">
                      <input
                        type="number"
                        name="mobile"
                        placeholder="Mobile number"
                        hidden
                        value={userPhone}
                        onChange={(e) => {
                          setMobile(e.target.value);
                        }}
                      />
                      <button
                        type="submit"
                        className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                        onClick={() => {
                          setVerifyWithEmail(false);
                          setVerifyWithPhone(true);
                          sendOtp();
                          console.log("Phone Verification");
                        }}
                      >
                        Phone
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div
              className={
                verifyWithPhone && sendOtpData === true
                  ? "container d-block"
                  : "container d-none"
              }
            >
              <div className="row d-flex justify-content-center">
                <div className="col-12">
                  <h2>We have sent you a verification code on your phone</h2>
                  <hr />
                  {/* <p className="text-max-800">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p> */}
                </div>
                <div className="col-lg-6">
                  <form onSubmit={onSubmit}>
                    <div className="error-container"></div>
                    <div className="form-group">
                      <label className="control-label col-xs-4">
                        Verification Code
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        name="otp"
                        placeholder="OTP Number"
                        required
                        value={otp}
                        onChange={(e) => {
                          setOtp(e.target.value);
                        }}
                      />
                    </div>
                    {/* <input hidden value={userEmail} {...register('email')}/> */}
                    <div className="form-group">
                      <button
                        type="submit"
                        className="btn btn-block hvr-sweep-to-right btn-primary btn-lg"
                      >
                        Verify
                      </button>
                    </div>
                    {/* <a href="reset-password.html">Lost your Password?</a> */}
                  </form>
                  <h4 className="text-center">
                    Don't have an account?
                    {/* <a href="/signup">Sign up here!</a> */}
                    &nbsp;{" "}
                    <Link to="/signup" style={{ textDecoration: "none" }}>
                      Signup Here
                    </Link>
                  </h4>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default ChooseVerificationType;
