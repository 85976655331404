import React from "react";
import YenePayCheckoutButton from "react-yenepay-express-checkout";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import { toast } from "react-toastify";
import { useMutation } from "react-query";

const PayWithYenePay = () => {
  const navigate = useNavigate();
  const userInfo = JSON.parse(localStorage.getItem("user"));
  const userEmail = userInfo.customer.email;
  let pricingData = {};
  const priceListData = [
    {
      id: 1,
      name: "One Month",
      months: "1 month",
      price: 1,
    },
    {
      id: 2,
      name: "Three Months",
      months: "3 months",
      price: 110,
    },
    {
      id: 3,
      name: "Six Months",
      months: "6 months",
      price: 215,
    },
    {
      id: 4,
      name: "Nine Months",
      months: "9 months",
      price: 325,
    },
    {
      id: 5,
      name: "One Year",
      months: "12 months",
      price: 435,
    },
  ];

  //Verify user
  const { mutateAsync, isLoading } = useMutation(
    AuthService.verifyAccountByOtp,
    {
      onSuccess: (response) => {
        console.log(response);
      },
      onError: () => {},
    }
  );

  //Payer Data
  const payerData = {
    email: userInfo.customer.email,
    buyerId: pricingData.buyerId,
    status: pricingData.status,
    transactionCode: pricingData.transactionCode,
    transactionId: pricingData.transactionId,
    merchantCode: pricingData.merchantCode,
    signature: pricingData.signature,
  };

  // alert(payerData)

  return (
    <div>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {priceListData?.map((prices) => {
              return (
                <div className="col-lg-4 mt-5">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title text-muted text-uppercase text-center">
                        {prices.name}
                      </h5>
                      <p className="card-price text-center">
                        {prices.price} Br
                        {/* <span className="period">/{prices.months}</span> */}
                      </p>
                      <hr />
                      <ul className="fa-ul">
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          <strong>New Movies</strong>
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          Streamit Special
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          American Tv Shows
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          Hollywood Movies
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          FHD (1080p) Video Quality
                        </li>
                        <li>
                          <span className="fa-li">
                            <i className="fas fa-check"></i>
                          </span>
                          Ad Free Entertainment
                        </li>
                      </ul>
                      <div
                        className="bg-primary m-4 mt-5"
                        style={{ width: "150px", borderRadius: "20px" }}
                      >
                        <YenePayCheckoutButton
                          ipnUrl="https://yourdomain.com/ipn"
                          successUrl="https://yourdomain.com/success"
                          merchantOrderId="OR01"
                          itemId="IT1001"
                          itemName="My Item"
                          unitPrice={prices.price}
                          quantity={1}
                          forceWhenDuplicate={true}
                          useSandbox={true}
                          merchantId="12411"
                          onSuccess={(pricingData) => {
                            console.log(pricingData);
                            localStorage.setItem("payerData", payerData);
                            const data = {
                              email: userEmail,
                            };
                            mutateAsync(data);
                            toast.success("Payment Successful, Please Login");
                            localStorage.setItem("isVerified", true);
                            // navigate("/home").then(window.location.reload());
                            // window.location.reload();
                          }}
                          onError={(error) => console.log(error)}
                          style={{ width: "80px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayWithYenePay;
