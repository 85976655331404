import React from "react";
import axios from "axios";
import { useInView } from "react-intersection-observer";
import {
  useInfiniteQuery,
  useQuery,
  useQueryClient,
  useMutation,
} from "react-query";
import LatestMoviesComponent from "../components/moviesComponent/LatestMoviesComponent";
import MovieService from "../services/MovieService";
import Loading from "../components/shared/Loading";

const InfiniteScrollMovies = () => {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const imageURL = process.env.REACT_APP_API_SERVER_IMAGE;

  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery(
    ["infiniteMovies"], MovieService.infiniteMovies,
    {
      getPreviousPageParam: (firstPage) => +firstPage.PageNumber ?? undefined,
      getNextPageParam: (lastPage) => +lastPage.PageNumber + 1 ?? undefined,
    }
  );

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  const { mutateAsync: wishListMutate, isLoading: wishListMutateLoading } =
    useMutation("addToWhishList", MovieService.addToWishList, {
      onSuccess: () => {
        queryClient.invalidateQueries("movieDetail");
      },
    });

  const onWhishListFormSubmit = (defaultValues) => {
    console.log(defaultValues);
    wishListMutate(defaultValues);
  };

  return (
    <div>
      {status === "loading" ? (
        <Loading />
      ) : status === "error" ? (
        <span>Error: {error.message}</span>
      ) : (
        <>
          <div>
            {/* <button
              onClick={() => fetchPreviousPage()}
              disabled={!hasPreviousPage || isFetchingPreviousPage}
            >
              {isFetchingPreviousPage
                ? "Loading more..."
                : hasPreviousPage
                ? "Load Older"
                : "Nothing more to load"}
            </button> */}
          </div>
          <section className="trending">
            <div className="container-fluid">
              <h2 className="block-title">Movies</h2>
              {data.pages.map((page) => (
                <div className="row" key={page._id}>
                  {page.data.map((movies) => {
                    return (
                      <div className="col-6 col-sm-6 col-md-4 col-lg-4 col-xl-2">
                        <LatestMoviesComponent
                          key={movies.id}
                          id={movies._id}
                          title={movies.title}
                          image={`${imageURL}/${movies.poster}`}
                          category={movies.category}
                          postedDate={movies.createdAt}
                          views={movies.views}
                          onWhishListFormSubmit={onWhishListFormSubmit}
                          // isLoading={moviesLoading}
                        />
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
          </section>
          {/* <div>{isFetching && !isFetchingNextPage ? <Loading /> : null}</div> */}
          <div className="row p-5">
            <button
              ref={ref}
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              className="btn btn-danger ml-5"
            >
              {isFetchingNextPage
                ? "Loading more..."
                : hasNextPage
                ? "Load Newer"
                : "Nothing more to load"}
            </button>
          </div>
        </>
      )}
      <hr />
    </div>
  );
};

export default InfiniteScrollMovies;
